import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const LandIcon = require("../../../images/icons/land-logo.png")
const HomeIcon = require("../../../images/icons/home.png")
const SearchIcon = require("../../../images/icons/search.png")
const FollowingIcon = require("../../../images/icons/following.png")

const DarkHomeIcon = require("../../../images/icons/dark/dark-home.png")
const DarkSearchIcon = require("../../../images/icons/dark/search.png")
const DarkFollowingIcon = require("../../../images/icons/dark/follow.png")
const DarkLandIcon = require("../../../images/icons/dark/tunip-logo-white.png")

const NavbarBox = styled.div `
    width: 100%;
    height: 50px;
    background-color: ${props => props.theme.backgroundColor};
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 9000;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NavbarItem = styled.a`
    margin: 0 auto;
`

function BottomBar() {
    const data = useSelector((state => state))
    // @ts-ignore
    const [isDarkMode] = [data.isDarkMode]
    const navigate = useNavigate()

    return (
        <NavbarBox>
            <NavbarItem onClick={() => navigate("/")}>
                <img src={isDarkMode ? DarkHomeIcon : HomeIcon} alt="profile icon" width={"35px"}/>
            </NavbarItem>
            <NavbarItem onClick={() => navigate("/explore")}>
                <img src={isDarkMode ? DarkSearchIcon : SearchIcon} alt="search icon" width={"35px"}/>
            </NavbarItem>
            <NavbarItem onClick={() => navigate("/following")}>
                <img src={isDarkMode ? DarkFollowingIcon : FollowingIcon} alt="profile icon" width={"27px"}/>
            </NavbarItem>
            <NavbarItem href={"https://tunip.land/"}>
                <img src={isDarkMode ? DarkLandIcon : LandIcon} alt="chat icon" width={"30px"}/>
            </NavbarItem>
        </NavbarBox>
    )
}

export default BottomBar