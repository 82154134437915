import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database"
import useWindowDimensions from "./getDisplaySize";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import AccountSetup from "../modals/accountSetup";
import BottomBar from "./mobile/bottomBar";

const HomeIcon = require("../../images/icons/home.png")
const NotificationIcon = require("../../images/icons/notification.png")
const SearchIcon = require("../../images/icons/search.png")
const FollowingIcon = require("../../images/icons/following.png")
const LandIcon = require("../../images/icons/land-logo.png")
const SettingIcon = require("../../images/icons/setting.png")

const DarkHomeIcon = require("../../images/icons/dark/dark-home.png")
const DarkNotificationIcon = require("../../images/icons/dark/notification.png")
const DarkSearchIcon = require("../../images/icons/dark/search.png")
const DarkFollowingIcon = require("../../images/icons/dark/follow.png")
const DarkLandIcon = require("../../images/icons/dark/tunip-logo-white.png")
const DarkSettingIcon = require("../../images/icons/dark/setting.png")

const SideBarBox = styled.div `
    width: 300px;
    margin-left: 80px;
  
    @media screen and (max-width: 1725px) {
        width: 80px;
    }
`

const NavigationBox = styled.div `
    margin-top: 125px;
    position: fixed;
`

const NavigationButton = styled.a `
    border-radius: 90px;
    display: flex;
    padding: 10px 15px;
    text-decoration: none;
    color: ${props => props.theme.color};
    width: auto;
    margin-bottom: 10px;
    transition: 0.3s;
    height: 50px;
    
    h1 {
        margin: auto 10px;
        font-size: 30px;
      
        @media screen and (max-width: 1725px) {
            display: none;
        }
    }
  
    img {
        width: 50px;
    }
  
    &:hover {
        background-color: ${props => props.theme.hover};
    }
`

const AccountBox = styled(NavigationButton) `
    display: flex;
    padding: 15px;
    bottom: 0;
    margin-bottom: 20px;
    width: 270px;
    position: fixed;
    
    img {
        border-radius: 90px;
    }
`

const AccountBoxDetail = styled.div`
    margin: auto 10px;
  
    h3 {
        margin: 0;
        font-size: 20px;
    }
  
    p {
        color: #7c7c7c;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
        font-size: 12px;
    }
    
    @media screen and (max-width: 1725px) {
        display: none;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(25px, 33px);
    margin: 2px 2px 2px 10px;
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
        margin-right: 0;
        margin-left: 15px;
    }
`

function MainSideBar() {
    const [isOnline, setIsOnline] = useState<boolean>(false)

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, text, isDarkMode] = [data.userObj, data.text, data.isDarkMode]
    const {width, height} = useWindowDimensions()
    const navigate = useNavigate()

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return (() => getStatus())
    }, [userObj])

    if (width > 1118 && height > 850) {
        return (
            <SideBarBox>
                <NavigationBox>
                    <NavigationButton onClick={() => navigate("/")}>
                        <img src={isDarkMode ? DarkHomeIcon : HomeIcon} alt="home icon"/>
                        <h1>{text.home}</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => navigate("/notification")}>
                        <img src={isDarkMode ? DarkNotificationIcon : NotificationIcon} alt="notification icon"/>
                        <h1>{text.notification}</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => navigate("/explore")}>
                        <img src={isDarkMode ? DarkSearchIcon : SearchIcon} alt="search icon"/>
                        <h1>{text.explore}</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => navigate("/following")}>
                        <img src={isDarkMode ? DarkFollowingIcon : FollowingIcon} alt="following icon"/>
                        <h1>{text.following}</h1>
                    </NavigationButton>
                    <NavigationButton href={"https://tunip.land"}>
                        <img src={isDarkMode ? DarkLandIcon : LandIcon} alt="land icon"/>
                        <h1>Land</h1>
                    </NavigationButton>
                    <NavigationButton onClick={() => navigate("/setting")}>
                        <img src={isDarkMode ? DarkSettingIcon : SettingIcon} alt="setting icon"/>
                        <h1>{text.settings}</h1>
                    </NavigationButton>
                </NavigationBox>
                <AccountBox>
                    <ProfileImage image={userObj.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight/>
                    ) : null}
                    <AccountBoxDetail>
                        <h3>{userObj.name}</h3>
                        <p>{"@" + userObj.id}</p>
                    </AccountBoxDetail>
                </AccountBox>
                <AccountSetup/>
            </SideBarBox>
        )
    } else {
        return (
            <>
                <AccountSetup/>
                <BottomBar/>
            </>
        )
    }
}

export default MainSideBar