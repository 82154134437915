import React, {useEffect, useState} from "react";
import SideContent from "../modules/mainSideContent";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {doc, getFirestore, onSnapshot, setDoc, updateDoc} from "firebase/firestore";

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
    text-align: center;
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const ToggleButton = styled.label`
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 35px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-right: 20px;
  
    &:after {
        content: '';
        position: absolute;
        top: 4.5px;
        left: 5px;
        width: 27px;
        height: 27px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    &:active:after {
        width: 35px;
    }
`

const SettingMenu = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto 0;
    box-shadow: ${props => props.theme.boxShadow};
    background-color: ${props => props.theme.backgroundColor};
    border-radius: 10px;
  
    p {
        margin: 20px;
        font-size: 20px;
        color: ${props => props.theme.color};
    }
  
    input {
        visibility: hidden;
    }

    input:checked + label {
        background: #00d2ff;
    }

    input:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }
  
    @media screen and (max-width: 620px) {
        width: 98%;
      
        p {
            font-size: 19px;
        }
    }
`

function DarkModeSetting() {
    const [darkModeChecked, setDarkModeChecked] = useState<boolean>(false)
    const [docExists, setDocExists] = useState<boolean>(false)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]

    useEffect(() => {
        const getDarkModeSetting = onSnapshot(doc(getFirestore(), `users/${userObj.userId}/setting`, "darkMode"), (doc) => {
            setDocExists(doc.exists())
            if (doc.exists()) {
                if (doc.data().darkMode !== undefined) {
                    setDarkModeChecked(doc.data().darkMode)
                }
            }
        })

        return(() => getDarkModeSetting())
    }, [data])

    async function onDarkModeChange(event :any) {
        if (docExists) {
            await updateDoc(doc(getFirestore(), `users/${userObj.userId}/setting`, "darkMode"), {
                darkMode: event.target.checked
            })
        } else {
            await setDoc(doc(getFirestore(), `users/${userObj.userId}/setting`, "darkMode"), {
                darkMode: event.target.checked
            })
        }
    }

    return (
        <MainPage>
            <MainContent>
                <PageTitle>{text.darkModeSetting}</PageTitle>
                <SettingMenu>
                    <p>{text.darkMode}</p>
                    <input type="checkbox" id="switch" onChange={onDarkModeChange} checked={darkModeChecked}/>
                    <ToggleButton htmlFor="switch">Toggle</ToggleButton>
                </SettingMenu>
            </MainContent>
            <SideContent/>
        </MainPage>
    )
}

export default DarkModeSetting