import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {collection, doc, getFirestore, onSnapshot} from "firebase/firestore";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getDatabase, onValue, ref} from "firebase/database";

const SideContentLayer = styled.div `
    width: 380px;
  
    @media screen and (max-width: 1455px) {
        display: none;
    }
  
    @media screen and (max-height: 750px) {
        display: none;
    }
`

const FixedBox = styled.div`
    position: fixed;
    overflow-y: auto;
    height: 100%;
    width: 405px;
`

const TagsLayer = styled.div`
    width: 380px;
    margin: 125px auto 0;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    background-color: ${props => props.theme.backgroundColor};
    
    h1 {
        margin: 0 0 0 18px;
        color: ${props => props.theme.color}
    }
`

const ProfileLayer = styled.div`
    display: flex;
    align-content: center;
    margin-top: 10px;
`

const ProfileNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
    color: ${props => props.theme.color}
`

const ProfileId = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 5px;
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(59px, -15px);
`

interface onlineUserProp {
    userObj :any
}

function OnlineUser({userObj} :onlineUserProp) {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [profile, setProfile] = useState<any>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getProfile = onSnapshot(doc(getFirestore(), "users", userObj.userId), (doc :any) => {
            setProfile(doc.data())
        })

        return(() => {
            getStatus()
            getProfile()
        })
    }, [userObj])

    function onProfileClick() {
        navigate(`/profile/${profile.id}`, {state: {userObj, profile}})
    }

    if (isOnline && profile !== null) {
        return (
            <ProfileLayer>
                <a onClick={onProfileClick}>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                </a>
                <div style={{margin: "11px", marginBottom: "0", display: "block"}}>
                    <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                    <ProfileId>
                        <p>{"@" + profile.id}</p>
                    </ProfileId>
                </div>
            </ProfileLayer>
        )
    } else {
        return null
    }
}

function MainSideContent() {
    const [following, setFollowing] = useState<object[]>([])

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]

    useEffect(() => {
        const getFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            let followingArray :object[] = []
            querySnapshot.forEach((doc :any) => {
                if (doc.exists) {
                    followingArray.push(doc.data())
                }
            })
            setFollowing(followingArray)
        })

        return(() => getFollowing())
    }, [userObj])

    return (
        <SideContentLayer>
            <FixedBox>
                <TagsLayer>
                    <h1>{text.onlineUsers}</h1>
                    {following.map((e :any, i :number) => (
                        <OnlineUser key={i} userObj={e}/>
                    ))}
                </TagsLayer>
            </FixedBox>
        </SideContentLayer>
    )
}

export default MainSideContent