import React, {useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {addDoc, collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import {getDownloadURL, getStorage, uploadString} from "firebase/storage";
import {ref as storageRef} from "firebase/storage"
import { v4 as uuidv4 } from "uuid"
import {getDatabase, push, ref, serverTimestamp} from "firebase/database";

const BinIcon = require("../../images/icons/bin.png")

const ModalOverBox = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 650px;
    max-height: 800px;
    border-radius: 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};
    text-align: center;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const ModalTitle = styled.h1`
    font-size: 35px;
    font-weight: normal;
`

const DropContainer = styled.input<{color :string}>`
    background-color: ${props => props.color};
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    appearance: none;
    padding: 100px 140px;
    width: 200px;
    margin-bottom: 30px;

    @media screen and (max-width: 620px) {
        padding: 50px 75px;
    }
`

const ImagePreviewBox = styled.div`
    border-radius: 20px;
    box-shadow: 1px 4px 10px #00000020;
    position: relative;
    width: 500px;
    margin: 20px auto;
  
    @media screen and (max-width: 620px) {
        width: 320px;
    }
`

const ImageDeleteButton = styled.a`
    background-color: #00000060;
    border-radius: 90px;
    margin: 10px;
    padding-left: 1.5px;
    padding-right: 1.5px;
    position: absolute;
    left: 0;
`

const ImagePreview = styled.img`
    width: 500px;
    border-radius: 22px;
  
    @media screen and (max-width: 620px) {
        width: 320px;
    }
`

const SendButton = styled.a<{themeColor :string, themeFontColor :string}>`
    font-size: 20px;
    padding: 10px 130px;
    background-color: ${props => props.themeColor};
    border-radius: 15px;
    color: ${props => props.themeFontColor};
`

const MarginLayer = styled.div`
    height: 30px;
`

interface ImageSendProps {
    showImageSendModal :boolean,
    setShowImageSendModal :(active :boolean) => void,
    chatObj :any,
    themeColor :string,
    themeFontColor :string
}

function ImageSend({showImageSendModal, setShowImageSendModal, chatObj, themeColor, themeFontColor} :ImageSendProps) {
    const [messageImageContent, setMessageImageContent] = useState<string>("")
    const [backgroundColor, setBackgroundColor] = useState<string>("#00000030")
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]

    function onFileChange(event :any) {
        const files = event.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = (finishedEvent :any) => {
            const result = finishedEvent.currentTarget.result
            setMessageImageContent(result)
        }
        try{
            reader.readAsDataURL(file)
        } catch (error) {
            console.error(error)
        }
    }

    async function sendMessage() {
        if (messageImageContent !== "") {
            if (new Date(chatObj.lastChatTime.seconds * 1000).getDate() !== new Date().getDate()) {
                await push(ref(getDatabase(), `chats/${chatObj.id}/messages`), {
                    type: "dateChange",
                    content: serverTimestamp(),
                    sendedAt: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
                    author: "tunip9410"
                })
            }

            setIsUploading(true)

            await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
                lastChat: `${userObj.name} sent a image`,
                lastChatTime: new Date()
            })

            await uploadString(storageRef(getStorage(), `${userObj.userId}/${uuidv4()}`), messageImageContent, "data_url").then((snapshot) => {
                return getDownloadURL(snapshot.ref)
            }).then(async downloadURL => {
                await push(ref(getDatabase(), `chats/${chatObj.id}/messages`), {
                    type: "image",
                    content: downloadURL,
                    author: userObj.userId,
                    sendedAt: serverTimestamp()
                })

                await addDoc(collection(getFirestore(), `chats/${chatObj.id}/images`), {
                    imageUrl :downloadURL,
                    author: userObj.userId,
                    sendedAt: new Date()
                })
            })

            setIsUploading(false)
            setMessageImageContent("")
            setShowImageSendModal(false)
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    if (showImageSendModal) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={() => setShowImageSendModal(false)}/>
                <ModalLayer>
                    <ModalTitle>Send image</ModalTitle>
                    {messageImageContent !== "" ? (
                        <ImagePreviewBox>
                            <ImagePreview src={messageImageContent} alt="image preview"/>
                            <ImageDeleteButton onClick={() => setMessageImageContent("")}>
                                <img src={BinIcon} alt="image delete button" width={"25px"}/>
                            </ImageDeleteButton>
                        </ImagePreviewBox>
                    ) : (
                        <DropContainer
                            type={"file"}
                            accept={"image/*"}
                            color={backgroundColor}
                            onChange={onFileChange}
                            onDragEnter={() => setBackgroundColor("#00000050")}
                            onDragLeave={() => setBackgroundColor("#00000030")}/>
                    )}
                    {isUploading ? (
                        <p>{text.uploading}</p>
                    ) : (
                        <SendButton themeColor={themeColor} themeFontColor={themeFontColor} onClick={sendMessage}>Send</SendButton>
                    )}
                    <MarginLayer/>
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default ImageSend