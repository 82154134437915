import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {arrayRemove, arrayUnion, deleteDoc, doc, getDoc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";
import {useSelector} from "react-redux";
import {deleteObject, getDownloadURL, getStorage, ref, uploadString} from "firebase/storage";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import InviteMember from "./inviteMember";

const BinIcon = require("../../images/icons/bin.png")
const InviteIcon = require("../../images/icons/follow.png")
const MuteIcon = require("../../images/icons/notification-mute.png")
const LeaveIcon = require("../../images/icons/leave.png")
const ThemeIcon = require("../../images/icons/theme.png")
const NotificationIcon = require("../../images/icons/notification.png")

const DarkInviteIcon = require("../../images/icons/dark/dark-follow.png")
const DarkMuteIcon = require("../../images/icons/dark/dark-notification-mute.png")
const DarkLeaveIcon = require("../../images/icons/dark/dark-leave.png")
const DarkThemeIcon = require("../../images/icons/dark/theme.png")
const DarkNotificationIcon = require("../../images/icons/dark/notification.png")

const ModalOverBox = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 850px;
    max-height: 800px;
    border-radius: 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};
    text-align: center;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    border-radius: 90px;
    margin: 40px auto 20px;
`

const ChatImageLayer = styled.div`
    width: 150px;
    height: 150px;
    margin: 40px auto 20px;
    position: relative;
`

const FirstImage = styled.div<{image :string}>`
    width: 105px;
    height: 105px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 90px;
`

const SecondImage = styled(FirstImage)`
    right: 0;
    bottom: 0;
    left: auto;
    top: auto;
`

const DropContainer = styled.input`
    background-color: ${props => props.color};
    border-radius: 120px;
    box-shadow: 1px 4px 10px #00000028;
    appearance: none;
    padding: 64px 0;
    width: 150px;

    @media screen and (max-width: 620px) {
        padding: 35px 75px;
    }
`

const ProfileImageInput = styled(DropContainer)`
    border: 0;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, 70%);
  
    @media screen and (max-width: 620px) {
        transform: translate(-50%, 0%);
        padding: 45px 0;
        width: 110px;
    }
`

const PersonalChatName = styled.h1`
    font-size: 30px;
    margin: 0 0 25px 0;
`

const ChatNameInput = styled.input`
    background-color: #00000000;
    border: 0;
    border-bottom: 3px solid ${props => props.theme.color};
    color: ${props => props.theme.color};
    font-size: 30px;
    margin-bottom: 25px;
    outline: none;
    text-align: center;
  
    @media screen and (max-width: 620px) {
        width: 250px;
    }
`

const ChatSettingMenuLayer = styled.div`
    display: flex;
    width: 80%;
    margin: 40px auto;
  
    @media screen and (max-width: 620px) {
        width: 95%;
    }
`

const ChatSettingItem = styled.a`
    margin: 0 auto;
  
    img {
        width: 50px;
    }
  
    @media screen and (max-width: 620px) {
        img {
            width: 40px;
        }
    }
`

const ThemeSettingLayer = styled.div`
    display: flex;
    margin: 20px 0;
`

const ThemeColorItem = styled.div<{color :string}>`
    width: 26px;
    height: 26px;
    border-radius: 90px;
    border: 6px solid #5b5b5b;
    box-shadow: 1px 4px 10px #00000020;
    background-color: ${props => props.color};
    margin: 0 auto;
`

interface ChatSettingProps {
    showChatSetting :boolean,
    setShowChatSetting :(active :boolean) => void,
    chatObj :any
}

function ChatSetting({showChatSetting, setShowChatSetting, chatObj} :ChatSettingProps) {
    const [chatImageArray, setChatImageArray] = useState<string[]>([])
    const [chatName, setChatName] = useState<string>("")
    const [imageInputBackground, setImageInputBackground] = useState<string>("#00000030")
    const [showInviteMember, setShowInviteMember] = useState<boolean>(false)
    const [showThemeSetting, setShowThemeSetting] = useState<boolean>(false)
    const [isMuted, setIsMuted] = useState<boolean>(false)

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, isDarkMode] = [data.userObj, data.isDarkMode]
    const navigate = useNavigate()

    useEffect(() => {
        if (chatObj.mutedMember !== undefined) {
            if (chatObj.mutedMember.indexOf(userObj.userId) > -1) {
                setIsMuted(true)
            } else {
                setIsMuted(false)
            }
        } else {
            setIsMuted(false)
        }

        if (chatObj.chatType === "personal") {
            const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
            const getProfile = onSnapshot(doc(getFirestore(), "users", userId[0]), (doc) => {
                if (doc.exists()) {
                    setChatName(doc.data().name)
                    setChatImageArray([doc.data().profilePhoto])
                }
            })

            return(() => getProfile())
        } else if (chatObj.chatType === "group") {
            if (chatObj.chatImage === "default") {
                const imageArray :string[] = []
                const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
                setTimeout(() => {
                    userId.map((e :string) => {
                        getDoc(doc(getFirestore(), "users", e)).then((doc) => {
                            if (doc.exists()) {
                                imageArray.push(doc.data().profilePhoto)
                                setChatImageArray(imageArray)
                            }
                        })
                    })
                }, 300)
            } else {
                setChatImageArray(chatObj.chatImage)
            }

            if (typeof chatObj.chatName === "string") {
                setChatName(chatObj.chatName)
            } else {
                setChatName(chatObj.chatName.toString())
            }
        }
    }, [userObj, chatObj])

    function onChatImageFileChange(event :any) {
        const files = event.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = async (finishedEvent :any) => {
            const result = finishedEvent.currentTarget.result
            try {
                await deleteObject(ref(getStorage(), chatObj.chatImage)).then(r => r)
            } finally {
                await uploadString(ref(getStorage(), `${userObj.userId}/${uuidv4()}`), result, "data_url").then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                }).then(async downloadURL => {
                    await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
                        chatImage: downloadURL
                    })
                    setChatImageArray([result])
                })
            }
        }
        try{
            reader.readAsDataURL(file)
        } catch (error) {
            console.error(error)
        }
    }

    async function onChatNameChange(event :any) {
        await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
            chatName: event.target.value
        })
    }

    async function onLeaveClick() {
        const ok = window.confirm("Are you sure want to leave this group?")
        if (ok) {
            await deleteDoc(doc(getFirestore(), `chats/${chatObj.id}/lastCheck`, userObj.userId))
            await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
                member: arrayRemove(userObj.userId)
            })

            navigate("/")
        }
    }

    function onThemeClick() {
        if (showThemeSetting) {
            setShowThemeSetting(false)
        } else {
            setShowThemeSetting(true)
        }
    }

    async function onThemeItemClick(color :string) {
        await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
            themeColor: color
        })
    }

    async function onMuteClick() {
        await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
            mutedMember: arrayUnion(userObj.userId)
        })
    }

    async function onUnmuteClick() {
        await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
            mutedMember: arrayRemove(userObj.userId)
        })
    }

    if (showChatSetting) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={() => setShowChatSetting(false)}/>
                <InviteMember chatObj={chatObj} showInviteMember={showInviteMember} setShowInviteMember={setShowInviteMember}/>
                <ModalLayer>
                    {chatImageArray.length === 1 ? (
                        <ProfileImage image={chatImageArray[0]}/>
                    ) : chatImageArray.length > 1 ? (
                        <ChatImageLayer>
                            <FirstImage image={chatImageArray[0]}/>
                            <SecondImage image={chatImageArray[1]}/>
                        </ChatImageLayer>
                    ) : (
                        <ProfileImageInput
                            type="file"
                            accept={"image/*"}
                            color={imageInputBackground}
                            onDragEnter={() => setImageInputBackground("#00000050")}
                            onDragLeave={() => setImageInputBackground("#00000030")}
                            onChange={onChatImageFileChange}/>
                    )}
                    {chatObj.chatType === "personal" ? (
                        <PersonalChatName>{chatName}</PersonalChatName>
                    ) : (
                        <ChatNameInput type="text" defaultValue={chatName} onChange={onChatNameChange}/>
                    )}
                    {chatObj.chatType === "group" ? (
                        <ChatSettingMenuLayer>
                            <ChatSettingItem onClick={() => setShowInviteMember(true)}>
                                <img src={isDarkMode ? DarkInviteIcon : InviteIcon} alt="invite icon"/>
                                <p>Invite</p>
                            </ChatSettingItem>
                            {!isMuted ? (
                                <ChatSettingItem onClick={onMuteClick}>
                                    <img src={isDarkMode ? DarkMuteIcon : MuteIcon} alt="mute icon"/>
                                    <p>Mute</p>
                                </ChatSettingItem>
                            ) : (
                                <ChatSettingItem onClick={onUnmuteClick}>
                                    <img src={isDarkMode ? DarkNotificationIcon : NotificationIcon} alt="mute icon"/>
                                    <p>Unmute</p>
                                </ChatSettingItem>
                            )}
                            <ChatSettingItem onClick={onThemeClick}>
                                <img src={isDarkMode ? DarkThemeIcon : ThemeIcon} alt="theme icon"/>
                                <p>Theme</p>
                            </ChatSettingItem>
                            <ChatSettingItem onClick={onLeaveClick}>
                                <img src={isDarkMode ? DarkLeaveIcon : LeaveIcon} alt="leave icon"/>
                                <p>Leave</p>
                            </ChatSettingItem>
                        </ChatSettingMenuLayer>
                    ) : (
                        <ChatSettingMenuLayer>
                            {!isMuted ? (
                                <ChatSettingItem onClick={onMuteClick}>
                                    <img src={isDarkMode ? DarkMuteIcon : MuteIcon} alt="mute icon"/>
                                    <p>Mute</p>
                                </ChatSettingItem>
                            ) : (
                                <ChatSettingItem onClick={onUnmuteClick}>
                                    <img src={isDarkMode ? DarkNotificationIcon : NotificationIcon} alt="mute icon"/>
                                    <p>Unmute</p>
                                </ChatSettingItem>
                            )}
                            <ChatSettingItem onClick={onThemeClick}>
                                <img src={isDarkMode ? DarkThemeIcon : ThemeIcon} alt="theme icon"/>
                                <p>Theme</p>
                            </ChatSettingItem>
                        </ChatSettingMenuLayer>
                    )}
                    {showThemeSetting ? (
                        <ThemeSettingLayer>
                            <ChatSettingItem>
                                <ThemeColorItem color={"#00d2ff"} onClick={() => onThemeItemClick("default")}/>
                                <p>Default</p>
                            </ChatSettingItem>
                            <ChatSettingItem onClick={() => onThemeItemClick("yellow")}>
                                <ThemeColorItem color={"#ffd043"}/>
                                <p>Yellow</p>
                            </ChatSettingItem>
                            <ChatSettingItem onClick={() => onThemeItemClick("green")}>
                                <ThemeColorItem color={"#2fd076"}/>
                                <p>Green</p>
                            </ChatSettingItem>
                            <ChatSettingItem onClick={() => onThemeItemClick("red")}>
                                <ThemeColorItem color={"#ff4a4a"}/>
                                <p>Red</p>
                            </ChatSettingItem>
                            <ChatSettingItem onClick={() => onThemeItemClick("pink")}>
                                <ThemeColorItem color={"#e3acce"}/>
                                <p>Pink</p>
                            </ChatSettingItem>
                            <ChatSettingItem onClick={() => onThemeItemClick("black")}>
                                <ThemeColorItem color={"#000000"}/>
                                <p>Black</p>
                            </ChatSettingItem>
                        </ThemeSettingLayer>
                    ) : null}
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default ChatSetting