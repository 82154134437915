import React from "react";
import styled from "styled-components";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth"

const banner = require("../images/banner.png")
const winter = require("../images/winter.png")

const Background = styled.div `
    width: 100%;
    height: 100%;
    background-image: url(${winter});
    background-size: cover;
    background-position: center;
`

const Over = styled.div `
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LoginButton = styled.a `
    position: relative;
    display: inline-block;
    padding: 15px 70px;
    color: #9af317;
    text-transform: uppercase;
    letter-spacing: 7px;
    text-decoration: none;
    font-size: 24px;
    border: 3px solid #9af317;
    margin-top: 30px;
  
    @media screen and (max-width: 610px) {
        padding: 10px 35px;
        font-size: 14px;
        margin-top: 20px;
        letter-spacing: 5px;
    }
`


const MainContent = styled.div `
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
  
    img {
        @media screen and (max-width: 610px) {
            width: 300px;
        }
    }
`


function Login() {
    function onGoogleClick() {
        const provider = new GoogleAuthProvider()
        signInWithPopup(getAuth(), provider).then(r => r)
    }

    return (
        <Background>
            <Over>
                <MainContent>
                    <img src={banner} alt="banner" width={"600px"}/>
                    <div/>
                    <LoginButton onClick={onGoogleClick}>
                        Log in
                    </LoginButton>
                </MainContent>
            </Over>
        </Background>
    )
}

export default Login