import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainSideContent from "./modules/mainSideContent";
import {useSelector} from "react-redux";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    updateDoc,
    where,
    increment
} from "firebase/firestore";
import {useNavigate} from "react-router-dom";

const SearchIcon = require("../images/icons/search.png")
const DarkSearchIcon = require("../images/icons/dark/search.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin: 125px 50px 0;

    @media screen and (max-width: 1118px) {
        margin: 90px auto 0;
    }
    
    @media screen and (max-height: 750px) {
        margin: 90px auto 0;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const SearchBox = styled.div `
    background-color: ${props => props.theme.backgroundColor};
    width: 600px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    margin: 0 auto;
    
    img {
        margin-left: 5px;
    }
    
    input {
        position: absolute;
        width: 540px;
        height: 40px;
        border: 0;
        background-color: #00000000;
        font-size: 35px;
        padding-top: 5px;
        outline: none;
        margin: 0 auto;
        color: ${props => props.theme.color};
    }
  
    @media screen and (max-width: 620px) {
        width: 300px;
        height: 40px;
      
        img {
            width: 35px;
            margin-top: 3px;
        }
      
        input {
            height: 30px;
            width: 240px;
            font-size: 25px;
        }
    }
`

const TodayTrendLayer = styled.div`
    width: 700px;
    box-shadow: 1px 4px 10px #00000028;
    border-radius: 10px;
    margin: 70px auto 15px;
    background-color: ${props => props.theme.backgroundColor};
    
    h1 {
        text-align: center;
        margin-bottom: 0;
        padding: 20px;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
    }
    
    @media screen and (max-width: 620px) {
        width: 350px;
        margin: 30px auto 15px;
    }
`

const SearchSuggestionLayer = styled(TodayTrendLayer)`
    padding-top: 20px;
`
const TagSearchLayer = styled.a`
    display: flex;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 20px;
    align-items: center;
    text-decoration: none;
    
    img {
        padding: 5px;
        border-radius: 90px;
        border: 1px solid #a2a2a2;
    }
    
    h2 {
        margin: 0 0 0 15px;
        color: ${props => props.theme.color};
    }
  
    @media screen and (max-width: 620px) {
        width: 310px;
      
        h2 {
            font-size: 20px;
        }
      
        img {
            width: 40px;
        }
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 60px;
    height: 60px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 50px;
        height: 50px;
    }
`

const Margin = styled.div`
    height: 50px;
`

const UserSuggestionItemLayer = styled.div`
    text-align: center;
    border: 1px solid #c5c5c5;
    border-radius: 10px;
    margin: 0 5px;
`

const UserSuggestionName = styled.p`
    font-size: 18px;
    margin: 0 auto;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${props => props.theme.color};
  
    @media screen and (max-width: 620px) {
        font-size: 15px;
        width: 90px;
    }
`

const UserSuggestionId = styled.p`
    font-size: 10px;
    margin-top: 0;
    color: #7c7c7c;
`

const UserSuggestionProfileImage = styled(ProfileImage)`
    width: 90px;
    height: 90px;
    margin: 15px;
  
    @media screen and (max-width: 620px) {
        width: 70px;
        height: 70px;
        margin: 10px 15px;
    }
`

const UserSuggestionLayer = styled.div`
    display: flex;
    margin: 0 20px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 20px;
  
    @media screen and (max-width: 6200px) {
        margin: 0 10px;
        padding-bottom: 10px;
    }
`

interface SearchSuggestionItemProps {
    userId :string
}

function SearchSuggestionItem({userId} :SearchSuggestionItemProps) {
    const [profile, setProfile] = useState<any>()
    const navigate = useNavigate()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        if (userId !== undefined) {
            const getProfile = onSnapshot(query(collection(getFirestore(), "users"), where("id", "==", userId)), (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        setProfile(doc.data())
                    }
                })
            })

            return (() => getProfile())
        }
    }, [userId])

    async function onSearchClick() {
        const date :string = new Date().getFullYear().toString() + new Date().getMonth().toString() + new Date().getDate().toString()
        let docIdArray :string[] = []
        const querySnapshot = await getDocs(query(collection(getFirestore(), "search"), where("date", "==", date), where("country", "==", userObj.location.country)))
        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                docIdArray.push(doc.id)
            }
        })

        if (docIdArray.length !== 0) {
            await updateDoc(doc(getFirestore(), "search", docIdArray[0]), {
                ["@" + profile.id]: increment(1)
            })
        } else {
            await addDoc(collection(getFirestore(), "search"), {
                ["@" + profile.id]: increment(1),
                date: date,
                country: userObj.location.country
            })
        }

        navigate(`/search?q=${encodeURIComponent(profile.name)}`)
    }

    if (profile !== undefined) {
        if (!profile.private) {
            return (
                <TagSearchLayer onClick={onSearchClick}>
                    <ProfileImage image={profile.profilePhoto}/>
                    <h2>{profile.name}</h2>
                </TagSearchLayer>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

function UserSuggestionItem({userId} :SearchSuggestionItemProps) {
    const [profile, setProfile] = useState<any>()
    const navigate = useNavigate()

    useEffect(() => {
        if (userId !== undefined) {
            const getProfile = onSnapshot(doc(getFirestore(), "users", userId), (doc) => {
                if (doc.exists()) {
                    setProfile(doc.data())
                }
            })
            return(() => getProfile())
        }
    }, [userId])

    if (profile !== undefined) {
        if (!profile.private) {
            return (
                <UserSuggestionItemLayer onClick={() => navigate(`/search?q=${encodeURIComponent(profile.name)}`)}>
                    <UserSuggestionProfileImage image={profile.profilePhoto}/>
                    <UserSuggestionName>{profile.name}</UserSuggestionName>
                    <UserSuggestionId>{"@" + profile.id}</UserSuggestionId>
                </UserSuggestionItemLayer>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

function Explore() {
    const [searchSuggestArray, setSearchSuggestArray] = useState<object[]>([])
    const [searchArray, setSearchArray] = useState<object[]>([])
    const [keyWord, setKeyWord] = useState<string>("")
    const [userSuggestionArray, setUserSuggestionArray] = useState<string[]>([])
    const [followingUser, setFollowingUser] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [isDarkMode, userObj] = [data.isDarkMode, data.userObj]

    useEffect(() => {
        getDoc(doc(getFirestore(), "users", "searchArray")).then((doc) => {
            if (doc.exists()) {
                setSearchArray(doc.data().list)
            }
        })

        getDocs(collection(getFirestore(), `users/${userObj.userId}/followings`)).then((docs) => {
            const followingArray :object[] = []
            docs.forEach((doc) => {
                if (doc.exists()) {
                    followingArray.push(doc.data())
                }
            })

            setFollowingUser(followingArray)
        })
    }, [])

    useEffect(() => {
        let suggestionArray :string[] = []
        followingUser.slice(0, 4).map((user :any) => {
            getDocs(collection(getFirestore(), `users/${user.userId}/followings`)).then((followingDocs) => {
                const followingUserArray :string[] = []
                followingDocs.forEach((doc) => {
                    if (doc.exists()) {
                        followingUserArray.push(doc.data().userId)
                    }
                })
                suggestionArray = [...suggestionArray, ...followingUserArray.slice(0, 3)]
                setUserSuggestionArray(Array.from(new Set(suggestionArray)))
            })
        })
    }, [followingUser])

    function onSearchChange(event :any) {
        setKeyWord(event.target.value)

        if (event.target.value.length !== 0) {
            if (event.target.value.indexOf("@") === 0) {
                if (searchArray.length !== 0) {
                    setSearchSuggestArray(searchArray.filter((e: any) => e.id.search(event.target.value.slice(1)) !== -1))
                }
            } else {
                if (searchArray.length !== 0) {
                    setSearchSuggestArray(searchArray.filter((e: any) => e.name.search(event.target.value) !== -1))
                }
            }
        }
    }

    return (
        <MainPage>
            <MainContent>
                <PageTitle>Explore</PageTitle>
                <SearchBox>
                    <img src={isDarkMode ? DarkSearchIcon : SearchIcon} alt="search icon" width={"50px"}/>
                    <input type="text" placeholder={"Search"} onChange={onSearchChange}/>
                </SearchBox>
                {keyWord.length !== 0 ? (
                    <SearchSuggestionLayer>
                        {searchSuggestArray.slice(0, 7).map((e :any, i) => (
                            <SearchSuggestionItem userId={e.id} key={i}/>
                        ))}
                    </SearchSuggestionLayer>
                ) : (
                    <>
                        <SearchSuggestionLayer>
                            <UserSuggestionLayer>
                                {userSuggestionArray.map((e, i) => (
                                    <UserSuggestionItem userId={e} key={i}/>
                                ))}
                            </UserSuggestionLayer>
                        </SearchSuggestionLayer>
                    </>
                )}
                <Margin/>
            </MainContent>
            <MainSideContent/>
        </MainPage>
    )
}

export default Explore