import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {doc, getDoc, getFirestore, onSnapshot, setDoc, updateDoc} from "firebase/firestore";
import {getDatabase, ref, onValue, push, set, serverTimestamp, query, limitToLast} from "firebase/database";
import useIsTyping from "use-is-typing";
import Message from "./modules/message";
import ChatSideContent from "./modules/chatSideContent";
import ImageSend from "./modals/imageSend";
import useWindowDimensions from "./modules/getDisplaySize";
import ChatMenu from "./modals/mobile/chatMenu";

const BackIcon = require("../images/icons/see-more.png")
const VoiceCallIcon = require("../images/icons/voice-call.png")
const VideoCallIcon = require("../images/icons/video.png")
const ImageIcon = require("../images/icons/image.png")
const PlusIcon = require("../images/icons/plus.png")
const ArrowIcon = require("../images/icons/arrow.png")
const SeeMoreIcon = require("../images/icons/back.png")
const GifIcon = require("../images/icons/gif.png")

const DarkImageIcon = require("../images/icons/dark/dark-image.png")
const DarkPlusIcon = require("../images/icons/dark/dark-plus.png")
const DarkSeeMoreIcon = require("../images/icons/dark/back.png")
const DarkGifIcon = require("../images/icons/dark/gif.png")

const WhiteArrowIcon = require("../images/icons/dark/arrow.png")
const WhiteBackIcon = require("../images/icons/dark/see-more.png")
const WhiteVideoCallIcon = require("../images/icons/dark/video.png")
const WhiteVoiceCallIcon = require("../images/icons/dark/voice-call.png")

const POPSound = require("../sounds/POP.mp3")

const MainPage = styled.div`
    display: flex;
    color: ${props => props.theme.color};
  
    @media screen and (max-width: 1118px) {
        width: 100%;
    }
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    text-align: center;
  
    @media screen and (max-width: 1360px) {
        margin-left: 30px;
    }
  
    @media screen and (max-height: 950px) {
        margin-left: 30px;
    }

    @media screen and (max-width: 1340px) {
        width: 700px;
        margin-right: 10px;
    }
  
    @media screen and (max-width: 1118px) {
        margin: 0;
        width: 100%;
    }
`

const TopMargin = styled.div`
    height: 110px;
    width: 100%;
    background-color: ${props => props.theme.themeColor};
    position: fixed;
    z-index: -100;
  
    @media screen and (max-width: 1360px) {
        height: 30px;
        z-index: 0;
    }
  
    @media screen and (max-height: 950px) {
        height: 30px;
        z-index: 0;
    }
`

const ChatTitleLayer = styled.div<{bgImage :string, fontColor :string}>`
    display: flex;
    border-radius: 10px;
    align-items: center;
    height: 70px;
    background-color: ${props => props.bgImage};
    color: ${props => props.fontColor};
    justify-content: space-between;
    position: fixed;
    width: 850px;
    box-shadow: 1px 4px 10px #00000020;
    top: 110px;
    
    @media screen and (max-width: 1360px) {
        top: 30px;
    }
  
    @media screen and (max-height: 950px) {
        top: 30px;
    }
  
    @media screen and (max-width: 1340px) {
        width: 700px;
    }
  
    @media screen and (max-width: 1118px) {
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 0;
    }
  
    @media screen and (max-width: 620px) {
        height: 60px;
    }
`

const BackButton = styled.a`
    width: 60px;
    height: 60px;
  
    img {
        width: 60px;
    }
  
    @media screen and (max-width: 620px) {
        width: 50px;
        height: 50px;
        
        img {
            width: 50px;
        }
    }
`

const ChatNameLayer = styled.div<{fontColor :string}>`
    text-align: left;
    margin-left: 10px;
  
    h3 {
        margin: 0;
        font-size: 20px;
        color: ${props => props.fontColor};
        font-weight: normal;
    }
  
    p {
        margin: 0;
        font-size: 10px;
        color: #7c7c7c;
    }
  
    @media screen and (max-width: 620px) {
        margin-left: 7px;
        
        h3 {
            font-size: 15px;
        }
      
        p {
            font-size: 7px;
        }
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 40px;
        height: 40px;
        margin-right: 0;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #00d02a;
    position: absolute;
    transform: translate(95px, 17px);

    @media screen and (max-width: 620px) {
        transform: translate(78px, 14px);
        width: 12px;
        height: 12px;
    }
`

const LeftSideLayer = styled.div`
    display: flex;
    align-items: center;
`

const RightSideLayer = styled.div`
    display: flex;
    margin-right: 5px;
  
    a {
        margin: 10px 5px;
        height: 50px;
    }
    
    img {
        width: 50px;
    }
  
    @media screen and (max-width: 620px) {
        a {
            height: 35px;
        }
      
        img {
            width: 35px;
        }
    }
`

const MessageInputLayer = styled.div`
    display: flex;
    width: 850px;
    position: fixed;
    bottom: 25px;
    align-items: center;

    @media screen and (max-width: 1340px) {
        width: 700px;
    }
  
    @media screen and (max-width: 1118px) {
        width: 97%;
        justify-content: center;
        bottom: 15px;
    }
`

const TextMessageInput = styled.input<{isTyping :boolean}>`
    height: 50px;
    border-radius: 90px;
    border: 0;
    font-size: 30px;
    outline: none;
    padding: 0 17px;
    background-color: ${props => props.theme.backgroundColor};
    box-shadow: ${props => props.theme.boxShadow};
    width: 710px;
    font-weight: normal;
    color: ${props => props.theme.color};
    margin: 0 10px 0 ${props => props.isTyping ? `0` : `5px`};
    
    @media screen and (max-width: 620px) {
        width: 80%;
        height: 35px;
        font-size: 20px;
        padding: 0 10px;
    }
`

const MessageIcon = styled.img`
    width: 50px;
  
    @media screen and (max-width: 620px) {
        width: 35px;
    }
`

const SendButton = styled.a<{themeColor :string}>`
    background-color: ${props => props.themeColor};
    border-radius: 90px;
    box-shadow: ${props => props.theme.boxShadow};
    width: 50px;
    height: 50px;
  
    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
    }
`

const MessageLayer = styled.div`
    margin-top: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  
    @media screen and (max-width: 1360px) {
        margin-top: 110px;
    }

    @media screen and (max-width: 1340px) {
        width: 700px;
    }
  
    @media screen and (max-width: 1118px) {
        width: 100%;
        margin-top: 70px;
    }
`

const Margin = styled.div`
    height: 80px;
  
    @media screen and (max-width: 1118px) {
        height: 65px;
    }
  
    @media screen and (max-width: 620px) {
        height: 55px;
    }
`

const TypingLayer = styled.div`
    display: flex;
    justify-content: left;
    text-align: left;
    margin: 10px 0;
`

const TypingName = styled.p`
    margin: 0 5px;
    position: absolute;
    min-width: 100px;
    z-index: -1000;
  
    @media screen and (max-width: 620px) {
        font-size: 13px;
    }
`

const TypingEffect = styled.div<{themeFontColor :string}>`
    padding: 8px 15px;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 10px 22px 22px 22px;
    background-color: ${props => props.theme.backgroundColor};
    margin: 25px 5px 0 5px;
    display: flex;
  
    div {
        width: 10px;
        height: 10px;
        border-radius: 90px;
        background-color: #7c7c7c;
        margin: 7px 2px;
    }
  
    @media screen and (max-width: 620px) {
        margin-top: 21px;
        padding: 5px 10px;
    }
`

const ChatImageLayer = styled.div`
    width: 50px;
    height: 50px;
    position: relative;
  
    @media screen and (max-width: 620px) {
        width: 40px;
        height: 40px;
    }
`

const FirstImage = styled.div<{image :string}>`
    width: 35px;
    height: 35px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 90px;
  
    @media screen and (max-width: 620px) {
        width: 28px;
        height: 28px;
    }
`

const SecondImage = styled(FirstImage)`
    right: 0;
    bottom: 0;
    left: auto;
    top: auto;
`

const HamburgerButton = styled.a<{themeFontColor :string}>`
    width: 50px;
    transform: translate(0, 5px);
  
    div {
        height: 6px;
        width: 35px;
        background-color: ${props => props.themeFontColor};
        margin: 5px auto;
        border-radius: 90px;
    }
  
    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
        transform: translate(0, 3px);
      
        div {
            width: 28px;
            height: 4px;
        }
    }
`

const SeeOlderMessageButton = styled.p<{themeColor :string}>`
    cursor: pointer;
    color: ${props => props.themeColor};
`

function Chat() {
    const {chatId} = useParams()
    const {width} = useWindowDimensions()

    const [chatObj, setChatObj] = useState<any>()
    const [themeColor, setThemeColor] = useState<string>("#00d2ff")
    const [themeFontColor, setThemeFontColor] = useState<string>("black")
    const [chatName, setChatName] = useState<string>("")
    const [chatSubName, setChatSubName] = useState<string>("")
    const [chatImageArray, setChatImageArray] = useState<string[]>([])
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [showImageSendModal, setShowImageSendModal] = useState<boolean>(false)
    const [showChatMenu, setShowChatMenu] = useState<boolean>(false)
    const [messageLimit, setMessageLimit] = useState<number>(150)
    const [getOlderMessageTrigger, setGetOlderMessageTrigger] = useState<number>(0)

    const [messageTextContent, setMessageTextContent] = useState<string>("")
    const [message, setMessage] = useState<any[]>([])
    const [messageArray, setMessageArray] = useState<any[]>([])
    const [isTyping, register] = useIsTyping({timeout: 5000})
    const [typingMember, setTypingMember] = useState<any[]>([])

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, isDarkMode] = [data.userObj, data.isDarkMode]
    const navigate = useNavigate()

    useEffect(() => {
        if (typeof chatId === "string") {
            const getChat = onSnapshot(doc(getFirestore(), "chats", chatId), (doc) => {
                if (doc.exists()) {
                    setChatObj({
                        ...doc.data(),
                        id: doc.id
                    })
                }
            })

            return(() => getChat())
        }
    }, [userObj, chatId])

    useEffect(() => {
        if (chatObj !== undefined) {
            setMessageLimit(messageLimit + 1)
            const getMessages = onValue(query(ref(getDatabase(), `chats/${chatObj.id}/messages`), limitToLast(messageLimit)), (snapshot) => {
                if (snapshot.exists()) {
                    setMessage(Object.entries(snapshot.val()).sort((a: any, b: any) => parseFloat(a[1].sendedAt) - parseFloat(b[1].sendedAt)))
                    setMessageArray(Object.values(snapshot.val()).sort((a: any, b: any) => parseFloat(a.sendedAt) - parseFloat(b.sendedAt)))
                }
            })

            const getTypings = onValue(ref(getDatabase(), `chats/${chatObj.id}/typing`), (snapshot) => {
                if (snapshot.exists()) {
                    setTypingMember(Object.values(snapshot.val()).filter((e :any) => e.isTyping && e.userId !== userObj.userId))
                }
            })

            return(() => {
                getMessages()
                getTypings()
            })
        }
    }, [chatObj, getOlderMessageTrigger])

    useEffect(() => {
        if (chatObj !== undefined) {
            if (chatObj.themeColor === "default") {
                setThemeColor("#00d2ff")
                setThemeFontColor("black")

                const metaTag = document.querySelector("meta[name='theme-color']")
                if (metaTag instanceof HTMLMetaElement) {
                    metaTag.setAttribute("content", "#00d2ff")
                }
            } else if (chatObj.themeColor === "yellow") {
                setThemeColor("#ffd043")
                setThemeFontColor("black")

                const metaTag = document.querySelector("meta[name='theme-color']")
                if (metaTag instanceof HTMLMetaElement) {
                    metaTag.setAttribute("content", "#ffd043")
                }
            } else if (chatObj.themeColor === "green") {
                setThemeColor("#2fd076")
                setThemeFontColor("black")

                const metaTag = document.querySelector("meta[name='theme-color']")
                if (metaTag instanceof HTMLMetaElement) {
                    metaTag.setAttribute("content", "#2fd076")
                }
            } else if (chatObj.themeColor === "red") {
                setThemeColor("#ff4a4a")
                setThemeFontColor("white")

                const metaTag = document.querySelector("meta[name='theme-color']")
                if (metaTag instanceof HTMLMetaElement) {
                    metaTag.setAttribute("content", "#ff4a4a")
                }
            } else if (chatObj.themeColor === "pink") {
                setThemeColor("#e3acce")
                setThemeFontColor("black")

                const metaTag = document.querySelector("meta[name='theme-color']")
                if (metaTag instanceof HTMLMetaElement) {
                    metaTag.setAttribute("content", "#e3acce")
                }
            } else if (chatObj.themeColor === "black") {
                if (isDarkMode) {
                    setThemeColor("#ffffff")
                    setThemeFontColor("black")

                    const metaTag = document.querySelector("meta[name='theme-color']")
                    if (metaTag instanceof HTMLMetaElement) {
                        metaTag.setAttribute("content", "#ffffff")
                    }
                } else {
                    setThemeColor("#000000")
                    setThemeFontColor("white")

                    const metaTag = document.querySelector("meta[name='theme-color']")
                    if (metaTag instanceof HTMLMetaElement) {
                        metaTag.setAttribute("content", "#000000")
                    }
                }
            }

            if (chatObj.chatType === "personal") {
                const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
                const getProfile = onSnapshot(doc(getFirestore(), "users", userId[0]), (doc) => {
                    if (doc.exists()) {
                        console.log(123)
                        setChatName(doc.data().name)
                        setChatSubName("@" + doc.data().id)
                        setChatImageArray([doc.data().profilePhoto])
                    }
                })

                const getOnlineStatus = onValue(ref(getDatabase(), `status/${userId[0]}/state`), (snapshot) => {
                    if (snapshot.exists() && snapshot.val() === "online") {
                        setIsOnline(true)
                    } else {
                        setIsOnline(false)
                    }
                })

                return(() => {
                    getProfile()
                    getOnlineStatus()
                })
            } else if (chatObj.chatType === "group") {
                if (chatObj.chatImage === "default") {
                    const imageArray :string[] = []
                    const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
                    setTimeout(() => {
                        userId.map((e :string) => {
                            getDoc(doc(getFirestore(), "users", e)).then((doc) => {
                                if (doc.exists()) {
                                    imageArray.push(doc.data().profilePhoto)
                                }
                                setChatImageArray(imageArray)
                            })
                        })
                    }, 300)
                } else {
                    setChatImageArray(chatObj.chatImage)
                }

                if (typeof chatObj.chatName === "string") {
                    const memberNameArray :string[] = []
                    chatObj.member.map((e :string) => {
                        getDoc(doc(getFirestore(), "users", e)).then((doc) => {
                            if (doc.exists()) {
                                memberNameArray.push(doc.data().name)
                            }
                            setChatSubName(memberNameArray.toString())

                        })
                    })
                    setChatName(chatObj.chatName)
                } else {
                    setChatName(chatObj.chatName.toString())
                    setChatSubName(chatObj.chatName.toString())
                }
            }
        }
    }, [chatObj, userObj])

    useEffect(() => {
        if (chatObj !== undefined) {
            set(ref(getDatabase(), `status/${userObj.userId}`), {
                state: "online",
                last_changed: serverTimestamp(),
                page: chatObj.id
            }).then(r => r)

            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
                if (chatObj.member.indexOf(userObj.userId) > -1) {
                    setDoc(doc(getFirestore(), `chats/${chatObj.id}/lastCheck`, userObj.userId), {
                        lastCheckTime: new Date()
                    }).then(r => r)
                }
            }, 500)
        }
    }, [message])

    useEffect(() => {
        if (chatObj !== undefined) {
            set(ref(getDatabase(), `chats/${chatObj.id}/typing/${userObj.userId}`), {
                userId: userObj.userId,
                name: userObj.name,
                profilePhoto: userObj.profilePhoto,
                isTyping: isTyping
            }).then(r => r)
        }
    }, [isTyping])

    function validURL(str :string) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(str);
    }

    async function sendMessage() {
        if (messageTextContent !== "") {
            if (new Date(chatObj.lastChatTime.seconds * 1000).getDate() !== new Date().getDate()) {
                await push(ref(getDatabase(), `chats/${chatObj.id}/messages`), {
                    type: "dateChange",
                    content: serverTimestamp(),
                    sendedAt: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
                    author: "tunip9410"
                })
            }
            const messageInput = document.querySelector("#message-input")
            if (messageInput instanceof HTMLInputElement) {
                messageInput.value = ""
            }

            await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
                lastChat: messageTextContent,
                lastChatTime: new Date()
            })

            if (validURL(messageTextContent)) {
                fetch(`https://port-0-tunipurl-r8xoo2mlebmddwq.sel3.cloudtype.app/url?url=${messageTextContent}`).then((res) => {
                    return res.json()
                }).then(async (json) => {
                    if (!json.error) {
                        await push(ref(getDatabase(), `chats/${chatObj.id}/messages`), {
                            type: "url",
                            content: messageTextContent,
                            title: json.title,
                            image: json.image,
                            author: userObj.userId,
                            sendedAt: serverTimestamp()
                        })
                    }
                })
            } else {
                await push(ref(getDatabase(), `chats/${chatObj.id}/messages`), {
                    type: "text",
                    content: messageTextContent,
                    author: userObj.userId,
                    sendedAt: serverTimestamp()
                })
            }

            setMessageTextContent("")
        }
    }

    function onTextInputKeyDown(event :any) {
        if (event.keyCode === 13) {
            sendMessage().then(r => r)
        }
    }

    if (chatObj !== undefined && chatObj.member.indexOf(userObj.userId) > -1) {
        return (
            <MainPage onDragEnter={() => setShowImageSendModal(true)}>
                <ChatMenu chatObj={chatObj} setShowChatMenu={setShowChatMenu} showChatMenu={showChatMenu}/>
                <MainContent>
                    <TopMargin/>
                    <ChatTitleLayer bgImage={themeColor} fontColor={themeFontColor}>
                        <LeftSideLayer>
                            <BackButton onClick={() => navigate("/")}>
                                <img src={themeFontColor === "white" ? WhiteBackIcon : BackIcon} alt="back icon"/>
                            </BackButton>
                            {chatImageArray.length === 1 ? (
                                <>
                                    <ProfileImage image={chatImageArray[0]}/>
                                    {isOnline ? (
                                        <OnlineLight/>
                                    ) : null}
                                </>
                            ) : (
                                <ChatImageLayer>
                                    <FirstImage image={chatImageArray[0]}/>
                                    <SecondImage image={chatImageArray[1]}/>
                                </ChatImageLayer>
                            )}
                            <ChatNameLayer fontColor={themeFontColor}>
                                <h3>{chatName}</h3>
                                <p>{chatSubName}</p>
                            </ChatNameLayer>
                        </LeftSideLayer>
                        <RightSideLayer>
                            <a>
                                <img src={themeFontColor === "white" ? WhiteVideoCallIcon : VideoCallIcon} alt="video call icon"/>
                            </a>
                            <a>
                                <img src={themeFontColor === "white" ? WhiteVoiceCallIcon : VoiceCallIcon} alt="voice call icon"/>
                            </a>
                            {width < 1720 ? (
                                <HamburgerButton themeFontColor={themeFontColor} onClick={() => setShowChatMenu(true)}>
                                    <div/>
                                    <div/>
                                    <div/>
                                </HamburgerButton>
                            ) : null}
                        </RightSideLayer>
                    </ChatTitleLayer>
                    <MessageLayer>
                        {messageLimit === message.length + 1 ? (
                            <SeeOlderMessageButton themeColor={themeColor} onClick={() => {
                                setMessageLimit(messageLimit + 150)
                                setGetOlderMessageTrigger(getOlderMessageTrigger + 1)
                            }}>See older message</SeeOlderMessageButton>
                        ) : null}
                        {message.map((e :any, i) => (
                            <>
                                <Message messageObj={e[1]} messageId={e[0]} key={i} themeColor={themeColor} themeFontColor={themeFontColor} nextMessage={messageArray[i + 1]} prevMessage={messageArray[i - 1]} chatObj={chatObj}/>
                            </>
                        ))}
                        {typingMember.map((e :any, i) => (
                            <TypingLayer key={i}>
                                <ProfileImage image={e.profilePhoto}/>
                                <div>
                                    <TypingName>{e.name}</TypingName>
                                    <TypingEffect themeFontColor={themeFontColor}>
                                        <div/>
                                        <div/>
                                        <div/>
                                    </TypingEffect>
                                </div>
                            </TypingLayer>
                        ))}
                        <Margin/>
                    </MessageLayer>
                    <MessageInputLayer>
                        <ImageSend setShowImageSendModal={setShowImageSendModal} showImageSendModal={showImageSendModal} chatObj={chatObj} themeColor={themeColor} themeFontColor={themeFontColor}/>
                        {isTyping ? (
                            <a onClick={() => register}>
                                <MessageIcon src={isDarkMode ? DarkSeeMoreIcon : SeeMoreIcon} alt={"see more icon"}/>
                            </a>
                        ) : (
                            <>
                                <a onClick={() => setShowImageSendModal(true)}>
                                    <MessageIcon src={isDarkMode ? DarkImageIcon : ImageIcon} alt="image icon"/>
                                </a>
                                <a>
                                    <MessageIcon src={isDarkMode ? DarkGifIcon : GifIcon} alt="gif icon"/>
                                </a>
                                <a>
                                    <MessageIcon src={isDarkMode ? DarkPlusIcon : PlusIcon} alt="plus icon"/>
                                </a>
                            </>
                        )}
                        <TextMessageInput
                            type="text"
                            name={"message-input"}
                            id={"message-input"}
                            ref={register}
                            isTyping={isTyping}
                            onKeyDown={onTextInputKeyDown}
                            onChange={(e) => setMessageTextContent(e.target.value)}
                            onClick={() => window.scrollTo(0, document.body.scrollHeight)}/>
                        <SendButton themeColor={themeColor} onClick={sendMessage}>
                            <MessageIcon src={themeFontColor === "white" ? WhiteArrowIcon : ArrowIcon} alt="send icon"/>
                        </SendButton>
                    </MessageInputLayer>
                </MainContent>
                <ChatSideContent chatObj={chatObj} themeColor={themeColor} themeFontColor={themeFontColor}/>
            </MainPage>
        )
    } else {
        return null
    }
}

export default Chat