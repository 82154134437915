import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    addDoc,
    collection,
    doc,
    getFirestore,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDoc
} from "firebase/firestore";
import {useSelector} from "react-redux";
import {getDatabase, onValue, ref} from "firebase/database";
import {useNavigate} from "react-router-dom";
import {format} from "timeago.js";

const FollowIcon = require("../../images/icons/follow.png")
const FollowingIcon = require("../../images/icons/following.png")

const ModalOverBox = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 900px;
    max-height: 800px;
    border-radius: 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};
    text-align: center;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const ProfileLayer = styled.div`
    margin-bottom: 10px;
`

const BannerImage = styled.div<{image ?:string}>`
    width: 800px;
    height: 190px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin: 50px auto 0;
    box-shadow: 1px 4px 10px #00000028;
  
    @media screen and (max-width: 850px) {
        width: 650px;
        height: 180px;
    }
  
    @media screen and (max-width: 620px) {
        width: 350px;
        height: 100px;
        margin-top: 30px;
    }
`

const EmptyBannerImage = styled(BannerImage)`
    background-color: ${props => props.theme.hover};
`

const ProfileImageLayer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 120px;
    box-shadow: 5px 4px 20px #00000028;
    position: absolute;
    background-color: white;
    transform: translate(0, -50%);
  
    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 200px;
    height: 200px;
    border-radius: 150px;

    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
    }
`

const UserName = styled.h1`
    font-size: 50px;
    font-weight: bolder;
    margin: 15px 0 0;
    text-align: center;

    @media screen and (max-width: 620px) {
        font-size: 30px;
        font-weight: bold;
        margin-top: 5px;
    }
`

const UserId = styled.p`
    font-size: 20px;
    color: #7C7C7C;
    text-align: center;
    margin: 0;

    @media screen and (max-width: 620px) {
        font-size: 10px;
    }
`

const OnlineLight = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(140px, -60px);

    @media screen and (max-width: 620px) {
        width: 30px;
        height: 30px;
        transform: translate(80px, -30px);
    }
`

const FollowLayer = styled.div`
    display: flex;
    color: black;
    position: absolute;
    right: 0;
    margin-top: 15px;
    
    @media screen and (max-width: 620px) {
        margin-top: 7px;
    }
`

const FollowButton = styled.a`
    cursor: pointer;
    background-color: #00d2ff;
    padding: 7px 15px;
    border-radius: 90px;
    display: flex;
    align-items: center;
      
    p {
        margin: 0 5px;
    }

    @media screen and (max-width: 620px) {
        font-size: 13px;
        padding: 7px 13px;
      
        p {
            margin: 0 2px;
        }
      
        img {
            width: 20px;
        }
    }
`

const ProfileNameMargin = styled.div`
    height: 100px;

    @media screen and (max-width: 620px) {
        height: 50px;
    }
`

const FollowingButton = styled(FollowButton)`
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    border: 3px solid #00d2ff;
    padding: 4px 7px;

    @media screen and (max-width: 620px) {
        border: 2px solid #00d2ff;
    }
`

const LandLayer = styled.div`
    margin: 30px 0 50px;
`

const LandButton = styled.a`
    margin: 50px 0;
    background-color: #00d2ff;
    color: black;
    padding: 10px 180px;
    border-radius: 10px;
    text-decoration: none;
    
    @media screen and (max-width: 620px) {
        padding: 10px 80px;
    }
`

const ProfileAline = styled.div`
    display: flex;
    justify-content: center;
    width: 800px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 850px) {
        width: 650px;
    }
    
    @media screen and (max-width: 620px) {
        width: 350px;
    }
`

const ChatProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 60px;
    height: 60px;
    border-radius: 90px;
    margin: 15px 10px 15px 25px;

    @media screen and (max-width: 620px) {
        width: 50px;
        height: 50px;
        margin-left: 15px;
    }
`

const ChatImageLayer = styled.div`
    width: 60px;
    height: 60px;
    position: relative;
    margin: 15px 10px 15px 25px;
  
    @media screen and (max-width: 620px) {
        width: 50px;
        height: 50px;
        margin-left: 15px;
    }
`

const FirstImage = styled.div<{image :string}>`
    width: 42px;
    height: 42px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 90px;
  
    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
    }
`

const SecondImage = styled(FirstImage)`
    right: 0;
    bottom: 0;
    left: auto;
    top: auto;
`

const ChatLayer = styled.a<{isDarkMode :boolean}>`
    background-color: ${props => props.isDarkMode ? `#131313` : `white`};
    color: ${props => props.theme.color};
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  
    @media screen and (max-width: 850px) {
        width: 620px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
    }
`

const ChatNameLayer = styled.div`
    display: flex;
    align-items: center;
  
    div {
        text-align: left;
    }
  
    h3 {
        margin: 0;
        font-size: 20px;
        font-weight: normal;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
  
    @media screen and (max-width: 850px) {
        h3 {
            width: 400px;
        }
    }
  
    @media screen and (max-width: 620px) {
        h3 {
            width: 150px;
        }
    }
`

const LastChat = styled.p<{isUnchecked :boolean}>`
    margin: 0;
    color: ${props => props.isUnchecked ? props.theme.color : `#7c7c7c`};
    font-weight: ${props => props.isUnchecked ? `bolder` : `normal`};
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 500px;
  
    @media screen and (max-width: 850px) {
        width: 380px;
    }
  
    @media screen and (max-width: 400px) {
        width: 400px;
    }
  
    @media screen and (max-width: 620px) {
        font-size: 13px;
        width: 150px;
    }
`

const LastChatTime = styled.p`
    margin: 20px;
    text-align: right;
   
    @media screen and (max-width: 620px) {
        font-size: 13px;
    }
`

const UncheckedLight = styled.div`
    width: 13.5px;
    height: 25px;
    background-color: #00d2ff;
    position: absolute;
    border-radius: 0 90px 90px 0;
  
    @media screen and (max-width: 620px) {
        height: 20px;
        width: 10px;
    }
`

const ChatListLayer = styled.div`
    margin: 35px auto;
    width: 750px;
  
    @media screen and (max-width: 850px) {
        width: 620px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
    }
`

const ChatOnlineLight = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(67px, 21px);

    @media screen and (max-width: 620px) {
        transform: translate(50px, 17px);
        width: 15px;
        height: 15px;
    }
`

interface ProfileProps {
    showProfile :boolean,
    setShowProfile :(active :boolean) => void,
    profile :any
}

interface ChatProps {
    chatObj :any
}

function Chat({chatObj} :ChatProps) {
    const [chatName, setChatName] = useState<string>("")
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [chatImageArray, setChatImageArray] = useState<string[]>([])
    const [isUnchecked, setIsUnchecked] = useState<boolean>(false)

    const navigate = useNavigate()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, isDarkMode] = [data.userObj, data.isDarkMode]

    useEffect(() => {
        if (chatObj.chatType === "personal") {
            const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
            const getProfile = onSnapshot(doc(getFirestore(), "users", userId[0]), (doc) => {
                if (doc.exists()) {
                    setChatName(doc.data().name)
                    setChatImageArray([doc.data().profilePhoto])
                }
            })

            const getOnlineStatus = onValue(ref(getDatabase(), `status/${userId[0]}/state`), (snapshot) => {
                if (snapshot.exists() && snapshot.val() === "online") {
                    setIsOnline(true)
                } else {
                    setIsOnline(false)
                }
            })

            return(() => {
                getProfile()
                getOnlineStatus()
            })
        } else if (chatObj.chatType === "group") {
            if (chatObj.chatImage === "default") {
                const imageArray :string[] = []
                const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
                setTimeout(() => {
                    userId.map((e :string) => {
                        getDoc(doc(getFirestore(), "users", e)).then((doc) => {
                            if (doc.exists()) {
                                imageArray.push(doc.data().profilePhoto)
                                setChatImageArray(imageArray)
                            }
                        })
                    })
                }, 300)
            } else {
                setChatImageArray(chatObj.chatImage)
            }

            if (typeof chatObj.chatName === "string") {
                setChatName(chatObj.chatName)
            } else {
                setChatName(chatObj.chatName.toString())
            }
        }
    }, [userObj, chatObj])

    useEffect(() => {
        getDoc(doc(getFirestore(), `chats/${chatObj.chatId}/lastCheck`, userObj.userId)).then((doc) => {
            if (doc.exists()) {
                if (doc.data().lastCheckTime.seconds <= chatObj.lastChatTime.seconds) {
                    setIsUnchecked(true)
                } else {
                    setIsUnchecked(false)
                }
            } else {
                setIsUnchecked(true)
            }
        })
    }, [chatImageArray])

    return (
        <ChatLayer isDarkMode={isDarkMode} onClick={() => navigate(`/chat/${chatObj.chatId}`)}>
            <ChatNameLayer>
                {isUnchecked ? (
                    <UncheckedLight/>
                ) : null}
                {chatImageArray.length === 1 ? (
                    <>
                        <ChatProfileImage image={chatImageArray[0]}/>
                        {isOnline ? (
                            <ChatOnlineLight/>
                        ) : null}
                    </>
                ) : (
                    <ChatImageLayer>
                        <FirstImage image={chatImageArray[0]}/>
                        <SecondImage image={chatImageArray[1]}/>
                    </ChatImageLayer>
                )}
                <div>
                    <h3>{chatName}</h3>
                    <LastChat isUnchecked={isUnchecked}>{chatObj.lastChat}</LastChat>
                </div>
            </ChatNameLayer>
            <LastChatTime>{format(chatObj.lastChatTime.seconds * 1000)}</LastChatTime>
        </ChatLayer>
    )
}

function ProfileModal({showProfile, setShowProfile, profile} :ProfileProps) {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [isFollowing, setIsFollowing] = useState<boolean>(false)
    const [chatList, setChatList] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            const followingArray :string[] = []
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    followingArray.push(doc.data().userId)
                }
            })

            if (followingArray.indexOf(profile.userId) > -1) {
                setIsFollowing(true)
            } else {
                setIsFollowing(false)
            }
        })

        const getChatQuery = query(collection(getFirestore(), "chats"), where("member", "array-contains", userObj.userId))
        const getChat = onSnapshot(getChatQuery, (querySnapshot) => {
            const chatArray :object[] = []
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    chatArray.push({
                        ...doc.data(),
                        chatId: doc.id
                    })
                }
            })
            setChatList(chatArray
                .sort((a: any, b: any) => parseFloat(b.lastChatTime.seconds) - parseFloat(a.lastChatTime.seconds))
                .filter((e :any) => e.member.indexOf(profile.userId) > -1))
        })

        return(() => {
            getStatus()
            getFollowing()
            getChat()
        })
    }, [showProfile])

    async function onFollowClick() {
        if (!isFollowing) {
            await addDoc(collection(getFirestore(), `users/${profile.userId}/notifications`), {
                content: `${userObj.name} started following you`,
                date: new Date(),
                isChecked: false,
                redirection: `/profile/${userObj.id}`,
                from: userObj.userId
            })

            await setDoc(doc(getFirestore(), `users/${profile.userId}/followers`, userObj.userId), {
                userId: userObj.userId,
                followedAt: new Date()
            })

            await setDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.userId), {
                userId: profile.userId,
                followedAt: new Date()
            })
        }
    }

    async function onFollowingClick() {
        if (isFollowing) {
            await deleteDoc(doc(getFirestore(), `users/${profile.userId}/followers`, userObj.userId))
            await deleteDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.userId))
        }
    }

    if (showProfile) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={() => setShowProfile(false)}/>
                <ModalLayer>
                    <ProfileLayer>
                        {profile.bannerPhoto !== undefined ? (
                            <BannerImage image={profile.bannerPhoto}/>
                        ) : (
                            <EmptyBannerImage/>
                        )}
                        <ProfileAline>
                            <ProfileImageLayer>
                                <ProfileImage image={profile.profilePhoto}/>
                                {isOnline ? (
                                    <OnlineLight></OnlineLight>
                                ) : null}
                            </ProfileImageLayer>
                            <FollowLayer>
                                {profile.userId !== userObj.userId && !isFollowing ? (
                                    <FollowButton onClick={onFollowClick}>
                                        <img src={FollowIcon} alt="follow icon" width={"30px"}/>
                                        <p>Follow</p>
                                    </FollowButton>
                                ) : profile.userId !== userObj.userId && isFollowing ? (
                                    <FollowingButton onClick={onFollowingClick}>
                                        <img src={FollowingIcon} alt="following icon" width={"30px"}/>
                                        <p>Following</p>
                                    </FollowingButton>
                                ) : null}
                            </FollowLayer>
                        </ProfileAline>
                        <ProfileNameMargin/>
                        <UserName>{profile.name}</UserName>
                        <UserId>{"@" + profile.id}</UserId>
                    </ProfileLayer>
                    <LandLayer>
                        <LandButton href={`https://tunip.land/profile/${profile.id}`}>View on tunip land</LandButton>
                    </LandLayer>
                    {profile.userId !== userObj.userId ? (
                        <ChatListLayer>
                            {chatList.map((e :any, i) => (
                                <Chat chatObj={e} key={i}/>
                            ))}
                        </ChatListLayer>
                    ) : null}
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default ProfileModal