import React, {useEffect, useState} from "react";
import MainSideContent from "./modules/mainSideContent";
import styled from "styled-components";
import {collection, doc, getFirestore, onSnapshot, getDocs, query, where, addDoc} from "firebase/firestore";
import {useSelector} from "react-redux";
import ProfileModal from "./modals/profile"
import {getDatabase, onValue, ref} from "firebase/database";
import {useNavigate} from "react-router-dom";

const ChatIcon = require("../images/icons/comment.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin: 125px 50px 0;

    @media screen and (max-width: 1118px) {
        margin: 90px auto 0;
    }
    
    @media screen and (max-height: 750px) {
        margin: 90px auto 0;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const ProfileLayer = styled.div`
    display: flex;
    color: ${props => props.theme.color};
  
    div {
        margin: auto 5px;
    }
  
    h3 {
        margin: 0;
        font-size: 20px;
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
  
    p {
        margin: 0;
        font-size: 12px;
        color: #7c7c7c;
    }
  
    @media screen and (max-width: 620px) {
        h3 {
            width: 160px;
        }
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 13px;

    @media screen and (max-width: 620px) {
        margin-right: 0;
        margin-left: 15px;
    }
`

const ChatButton = styled.a`
    padding: 0 8px;
    background-color: #00d2ff;
    display: flex;
    align-items: center;
    border-radius: 90px;
    height: 45px;
    
    h2 {
        margin: 0 10px 0 7px;
        font-size: 20px;
        font-weight: normal;
    }
  
    img {
        width: 30px;
    }
  
    @media screen and (max-width: 620px) {
        height: 41px;
      
        h2 {
            font-size: 17px;
            margin: 0 10px 0 5px;
        }
    }
`

const FlexBox = styled.div`
    display: flex;
    margin: 30px 70px;
    justify-content: space-between;
  
    @media screen and (max-width: 620px) {
        margin: 25px 10px;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(35px, 35px);
`

const Margin = styled.div`
    height: 40px;
`

interface ProfileProps {
    userId :any
}

function Profile({userId} :ProfileProps) {
    const [profile, setProfile] = useState<any>()
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [showProfile, setShowProfile] = useState<boolean>(false)
    const navigate = useNavigate()

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getProfile = onSnapshot(doc(getFirestore(), "users", userId), (doc) => {
            if (doc.exists()) {
                setProfile(doc.data())
            }
        })

        const getOnlineStatus = onValue(ref(getDatabase(), `status/${userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return (() => {
            getProfile()
            getOnlineStatus()
        })
    }, [])

    async function onChatClick() {
        await getDocs(query(collection(getFirestore(), "chats"), where("member", "in", [[userId, userObj.userId], [userObj.userId, userId]]), where("chatType", "==", "personal"))).then(async (querySnapshot) => {
            const chatArray: any[] = []
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    chatArray.push({
                        ...doc.data(),
                        id: doc.id
                    })
                }
            })

            if (chatArray.length === 0) {
                await addDoc(collection(getFirestore(), "chats"), {
                    chatType: "personal",
                    member: [userId, userObj.userId],
                    lastChat: `${userObj.name} started new chat`,
                    lastChatTime: new Date(),
                    themeColor: "default",
                    createdAt: new Date()
                }).then((docRef) => {
                    navigate(`/chat/${docRef.id}`)
                })
            } else {
                navigate(`/chat/${chatArray[0].id}`)
            }
        })
    }

    if (profile !== undefined) {
        return (
            <FlexBox>
                <ProfileModal profile={profile} setShowProfile={setShowProfile} showProfile={showProfile}/>
                <ProfileLayer>
                    <ProfileImage image={profile.profilePhoto} onClick={() => setShowProfile(true)}/>
                    {isOnline ? (
                        <OnlineLight/>
                    ) : null}
                    <div>
                        <h3>{profile.name}</h3>
                        <p>{"@" + profile.id}</p>
                    </div>
                </ProfileLayer>
                <ChatButton onClick={onChatClick}>
                    <img src={ChatIcon} alt="chat icon"/>
                    <h2>Chat</h2>
                </ChatButton>
            </FlexBox>
        )
    } else {
        return null
    }
}

function Following() {
    const [following, setFollowing] = useState<object[]>([])
    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]

    useEffect(() => {
        getDocs(collection(getFirestore(), `users/${userObj.userId}/followings`)).then((querySnapshot) => {
            let followingArray :object[] = []
            querySnapshot.forEach((doc) => {
                followingArray.push(doc.data())
            })
            setFollowing(followingArray)
        })
    }, [data])

    return (
        <MainPage>
            <MainContent>
                <PageTitle>{text.following}</PageTitle>
                {following.map((e :any, i) => (
                    <Profile userId={e.userId} key={i}/>
                ))}
                <Margin/>
            </MainContent>
            <MainSideContent/>
        </MainPage>
    )
}

export default Following