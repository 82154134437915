import React, {useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getDatabase, ref, update} from "firebase/database"

const BinIcon = require("../../images/icons/bin.png")
const CopyIcon = require("../../images/icons/copy.png")
const LikeIcon = require("../../images/icons/like.png")
const CommentIcon = require("../../images/icons/comment.png")
const EditIcon = require("../../images/icons/edit.png")

const DarkCopyIcon = require("../../images/icons/dark/copy.png")
const DarkLikeIcon = require("../../images/icons/dark/like.png")
const DarkEditIcon = require("../../images/icons/dark/edit.png")
const DarkBinIcon = require("../../images/icons/dark/bin.png")
const DarkCommentIcon = require("../../images/icons/dark/comment.png")

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11000;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 300px;
    max-height: 800px;
    border-radius: 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        border-radius: 30px 30px 0 0;
    }

    @media screen and (max-height: 850px) {
        width: 100%;
        bottom: 0;
        border-radius: 30px 30px 0 0;
    }
`

const MessageMenuItem = styled.a`
    display: flex;
    margin: 20px;
    align-items: center;
  
    p {
        font-size: 20px;
        margin: 0 10px;
    }
`

interface MessageMenuProps {
    showMessageMenu :boolean,
    setShowMessageMenu :(active :boolean) => void,
    messageObj :any,
    chatObj :any,
    messageId :string,
    isMyMessage :boolean
}

function MessageMenu({showMessageMenu, setShowMessageMenu, messageObj, chatObj, messageId, isMyMessage} :MessageMenuProps) {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [isDarkMode] = [data.isDarkMode]

    function onDeleteClick() {
        update(ref(getDatabase(), `/chats/${chatObj.id}/messages`), {
            [messageId]: null
        }).then(r => r)
        setShowMessageMenu(false)
    }

    function onCopyClick() {
        navigator.clipboard.writeText(messageObj.content).then(r => r)
        setIsCopied(true)
    }

    if (showMessageMenu) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={() => setShowMessageMenu(false)}/>
                <ModalLayer>
                    <MessageMenuItem onClick={onCopyClick}>
                        <img src={isDarkMode ? DarkCopyIcon : CopyIcon} alt="copy icon" width={"50px"}/>
                        <p>{isCopied ? "Copied ✨" : "Copy"}</p>
                    </MessageMenuItem>
                    <MessageMenuItem>
                        <img src={isDarkMode ? DarkCommentIcon : CommentIcon} alt="comment icon" width={"40px"} style={{margin: "5px"}}/>
                        <p>Add comment</p>
                    </MessageMenuItem>
                    <MessageMenuItem>
                        <img src={isDarkMode ? DarkLikeIcon : LikeIcon} alt="like icon" width={"40px"} style={{margin: "5px"}}/>
                        <p>Add emotion</p>
                    </MessageMenuItem>
                    {isMyMessage ? (
                        <>
                            {messageObj.type !== "url" ? (
                                <MessageMenuItem>
                                    <img src={isDarkMode ? DarkEditIcon : EditIcon} alt="edit icon" width={"50px"}/>
                                    <p>Edit</p>
                                </MessageMenuItem>
                            ) : null}
                            <MessageMenuItem onClick={onDeleteClick}>
                                <img src={isDarkMode ? DarkBinIcon : BinIcon} alt="delete icon" width={"50px"}/>
                                <p>Delete</p>
                            </MessageMenuItem>
                        </>
                    ) : null}
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default MessageMenu