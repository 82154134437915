import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import useWindowDimensions from "./getDisplaySize";
import {useNavigate} from "react-router-dom";
import {getDatabase, onValue, ref} from "firebase/database";
import NewMessage from "../modals/newMessage";
import {collection, doc, getDoc, getFirestore, onSnapshot, query, where} from "firebase/firestore";
import {format} from "timeago.js";

const SideBarBox = styled.div `
    width: 330px;
    margin-left: 80px;
  
    @media screen and (max-width: 1360px) {
        margin-left: 50px;
    }
  
    @media screen and (max-width: 1340px) {
        margin-left: 10px;
    }
`

const NavigationBox = styled.div `
    margin-top: 120px;
    position: fixed;
    overflow-y: auto;
    height: 83%;
    padding-top: 10px;
  
    @media screen and (max-width: 1360px) {
        margin-top: 30px;
    }
  
    @media screen and (max-height: 950px) {
        margin-top: 30px;
    }
`

const AccountBox = styled.div`
    color: ${props => props.theme.color};
    display: flex;
    padding: 15px 0;
    bottom: 0;
    margin-bottom: 10px;
    width: 270px;
    position: fixed;
    transition: 0.3s;
    height: 50px;
    border-radius: 90px;
  
    img {
        border-radius: 90px;
        width: 50px;
    }

    &:hover {
        background-color: ${props => props.theme.hover};
    }
`

const AccountBoxDetail = styled.div`
    margin: auto 10px;
  
    h3 {
        margin: 0;
        font-size: 20px;
    }
  
    p {
        color: #7c7c7c;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
        font-size: 12px;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(55px, 17px);
`

const MyStatusLight = styled(OnlineLight)`
    transform: translate(35px, 35px);
`

const NewMessageButton = styled.a`
    background-color: #00d2ff;
    font-size: 20px;
    padding: 10px 95px;
    border-radius: 10px;
`

const ChatLayer = styled.a`
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 330px;
`

const ChatNameLayer = styled.div`
    display: flex;
    align-items: center;
  
    div {
        text-align: left;
    }
  
    h3 {
        margin: 0;
        font-size: 20px;
        font-weight: normal;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
  
    p {
        margin: 0;
        color: #7c7c7c;
        font-size: 13px;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

const LastChatTime = styled.p`
    margin: 15px;
    font-size: 13px;
    text-align: right;
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
        margin-right: 0;
        margin-left: 15px;
    }
`

const ChatImage = styled(ProfileImage)`
    margin: 15px 10px 15px 20px;
`

const UncheckedLight = styled.div`
    background-color: #00d2ff;
    position: absolute;
    border-radius: 0 90px 90px 0;
    height: 20px;
    width: 10px;
`

const ChatListLayer = styled.div`
    margin-top: 25px;
`

const ChatImageLayer = styled.div`
    width: 50px;
    height: 50px;
    margin: 15px 10px 15px 20px;
    position: relative;
`

const FirstImage = styled.div<{image :string}>`
    width: 35px;
    height: 35px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 90px;
`

const SecondImage = styled(FirstImage)`
    right: 0;
    bottom: 0;
    left: auto;
    top: auto;
`

interface ChatItemProps {
    chatObj :any
}

function ChatItem({chatObj} :ChatItemProps) {
    const [chatName, setChatName] = useState<string>("")
    const [chatImageArray, setChatImageArray] = useState<string[]>([])
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [isUnchecked, setIsUnchecked] = useState<boolean>(false)

    const navigate = useNavigate()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        if (chatObj.chatType === "personal") {
            const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
            const getProfile = onSnapshot(doc(getFirestore(), "users", userId[0]), (doc) => {
                if (doc.exists()) {
                    setChatName(doc.data().name)
                    setChatImageArray([doc.data().profilePhoto])
                }
            })

            const getOnlineStatus = onValue(ref(getDatabase(), `status/${userId[0]}/state`), (snapshot) => {
                if (snapshot.exists() && snapshot.val() === "online") {
                    setIsOnline(true)
                } else {
                    setIsOnline(false)
                }
            })

            return(() => {
                getProfile()
                getOnlineStatus()
            })
        } else if (chatObj.chatType === "group") {
            if (chatObj.chatImage === "default") {
                const imageArray :string[] = []
                const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
                setTimeout(() => {
                    userId.map((e :string) => {
                        getDoc(doc(getFirestore(), "users", e)).then((doc) => {
                            if (doc.exists()) {
                                imageArray.push(doc.data().profilePhoto)
                            }
                            setChatImageArray(imageArray)
                        })
                    })
                }, 300)
            } else {
                setChatImageArray(chatObj.chatImage)
            }

            if (typeof chatObj.chatName === "string") {
                setChatName(chatObj.chatName)
            } else {
                setChatName(chatObj.chatName.toString())
            }
        }
    }, [userObj, chatObj])

    useEffect(() => {
        const getLastCheck = onSnapshot(doc(getFirestore(), `chats/${chatObj.chatId}/lastCheck`, userObj.userId), (doc) => {
            if (doc.exists()) {
                if (doc.data().lastCheckTime.seconds <= chatObj.lastChatTime.seconds)  {
                    setIsUnchecked(true)
                } else {
                    setIsUnchecked(false)
                }
            } else {
                setIsUnchecked(true)
            }
        })

        return(() => getLastCheck())
    }, [chatObj, userObj])

    return (
        <ChatLayer onClick={() => navigate(`/chat/${chatObj.chatId}`)}>
            <ChatNameLayer>
                {isUnchecked ? (
                    <UncheckedLight/>
                ) : null}
                {chatImageArray.length === 1 ? (
                    <>
                        <ChatImage image={chatImageArray[0]}/>
                        {isOnline ? (
                            <OnlineLight/>
                        ) : null}
                    </>
                ) : (
                    <ChatImageLayer>
                        <FirstImage image={chatImageArray[0]}/>
                        <SecondImage image={chatImageArray[1]}/>
                    </ChatImageLayer>
                )}
                <div>
                    <h3>{chatName}</h3>
                    <p>{chatObj.lastChat}</p>
                </div>
            </ChatNameLayer>
            <LastChatTime>{format(chatObj.lastChatTime.seconds * 1000)}</LastChatTime>
        </ChatLayer>
    )
}

function ChatSideBar() {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [showNewMessage, setShowNewMessage] = useState<boolean>(false)
    const [chatList, setChatList] = useState<object[]>([])

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj] = [data.userObj]
    const {width, height} = useWindowDimensions()

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val()) {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return (() => getStatus())
    }, [userObj])

    useEffect(() => {
        if (userObj.userId !== undefined) {
            const getChatQuery = query(collection(getFirestore(), "chats"), where("member", "array-contains", userObj.userId))
            const getChat = onSnapshot(getChatQuery, (querySnapshot) => {
                const chatArray :object[] = []
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        chatArray.push({
                            ...doc.data(),
                            chatId: doc.id
                        })
                    }
                })
                setChatList(chatArray.sort((a: any, b: any) => parseFloat(b.lastChatTime.seconds) - parseFloat(a.lastChatTime.seconds)))
            })

            return(() => getChat())
        }
    }, [userObj])

    if (width > 1118 && height > 650) {
        return (
            <SideBarBox>
                <NewMessage showNewMessage={showNewMessage} setShowNewMessage={setShowNewMessage}/>
                <NavigationBox>
                    <NewMessageButton onClick={() => setShowNewMessage(true)}>New message</NewMessageButton>
                    <ChatListLayer>
                        {chatList.map((e :any, i) => (
                            <ChatItem chatObj={e} key={i}/>
                        ))}
                    </ChatListLayer>
                </NavigationBox>
                <AccountBox>
                    <ProfileImage image={userObj.profilePhoto}/>
                    {isOnline ? (
                        <MyStatusLight/>
                    ) : null}
                    <AccountBoxDetail>
                        <h3>{userObj.name}</h3>
                        <p>{"@" + userObj.id}</p>
                    </AccountBoxDetail>
                </AccountBox>
            </SideBarBox>
        )
    } else {
        return null
    }
}

export default ChatSideBar