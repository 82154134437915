import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {doc, getFirestore, onSnapshot, updateDoc, arrayUnion, setDoc} from "firebase/firestore";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage"
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid"
import useWindowDimensions from "../modules/getDisplaySize";

const LocationIcon = require("../../images/icons/country.png")
const BinIcon = require("../../images/icons/bin.png")
const EnglishIcon = require("../../images/icons/english.png")
const KoreanIcon = require("../../images/icons/korean.png")

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const AccountSetupBox = styled.div`
    width: 800px;
    height: 700px;
    border-radius: 30px;
    background-color: white;
    position: fixed;
    box-shadow: 1px 4px 10px #00000028;
    z-index: 10000;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const NextButton = styled.a.attrs({href: "#"})`
    color: black;
    text-decoration: none;
    font-size: 30px;
    background-color: #00d2ff;
    padding: 10px 100px;
    border-radius: 90px;
    width: 80px;
    margin: 0 auto;
  
    @media screen and (max-width: 620px) {
        font-size: 20px;
        width: 40px;
        font-weight: bolder;
        position: absolute;
        bottom: 70px;
        left: 0;
        right: 0;
    }
`

const ModalTitle = styled.h1`
    font-size: 40px;
    margin: 70px;
    
    @media screen and (max-width: 620px) {
        font-size: 30px;
    }
`

const ModalBox = styled.div`
    text-align: center;
`

const Input = styled.input`
    width: 300px;
    height: 35px;
    font-size: 30px;
    border: 0;
    border-bottom: 3px solid black;
    outline: none;
`

const FlexBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ErrorMessage = styled.p`
    color: #FF4A4A;
    height: 19px;
`

const IdLabel = styled.label`
    font-size: 30px;
    margin-right: 5px;
`

const DropContainer = styled.input`
    background-color: ${props => props.color};
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    appearance: none;
    padding: 60px 200px;
    width: 200px;

    @media screen and (max-width: 620px) {
        padding: 35px 75px;
    }
`

const BannerImagePreviewBox = styled.div<{image :string}>`
    width: 600px;
    height: 142.5px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 1px 4px 10px #00000028;

    @media screen and (max-width: 620px) {
        width: 350px;
        height: 100px;
    }
`

const ImageDeleteButton = styled.a`
    float: left;
    background-color: #00000060;
    border-radius: 90px;
    margin: 10px;
    padding-left: 1.5px;
    padding-right: 1.5px;
`

const ProfileImageInput = styled(DropContainer)`
    padding: 64px 0;
    border: 0;
    border-radius: 120px;
    width: 150px;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -29%);
  
    @media screen and (max-width: 620px) {
        transform: translate(-50%, -80%);
        padding: 45px 0;
        width: 110px;
    }
`

const ProfileImagePreviewBox = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 120px;
    box-shadow: 1px 4px 10px #00000028;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -29%);
    background-color: white;

    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
        transform: translate(-50%, -80%);
    }
`

const ProfileImagePreview = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
    }
`

const AlineLeft = styled.div`
    text-align: left;
    margin: 30px 130px;
  
    label {
        margin-left: 10px
    }
  
    @media screen and (max-width: 620px) {
        margin: 30px 30px;
    }
`

const LanguageSelectionLayer = styled.div`
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  
    @media screen and (max-width: 500px) {
        width: 400px;
    }
`

const LanguageItemLayer = styled.a`
    margin: 20px;
    display: flex;
    align-items: center;
  
    img {
        width: 60px;
    }
  
    p {
        font-size: 20px;
        margin: 10px;
    }
  
    @media screen and (max-width: 500px) {
        img {
            width: 45px;
        }
    }
`

interface Iprops {
    thisPage :number
    setUserIdValue :(active: string) => void
    setThisPage :(active :number) => void
}

interface Iprops2 {
    thisPage :number
    setThisPage :(active :number) => void
}

interface ProfileSettingProps {
    thisPage :number
    userIdValue :string
}

function LanguageSettingPage({thisPage, setThisPage} :Iprops2) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [text, userObj] = [data.text, data.userObj]

    async function onEnglishClick() {
        await setDoc(doc(getFirestore(), `users/${userObj.userId}/setting`, "language"), {
            language: "english"
        })
    }

    async function onKoreanClick() {
        await setDoc(doc(getFirestore(), `users/${userObj.userId}/setting`, "language"), {
            language: "korean"
        })
    }

    if (thisPage === 0) {
        return (
            <ModalBox>
                <ModalTitle>{text.languageSetting}</ModalTitle>
                <LanguageSelectionLayer>
                    <LanguageItemLayer onClick={onEnglishClick}>
                        <img src={EnglishIcon} alt="english icon"/>
                        <p>English</p>
                    </LanguageItemLayer>
                    <LanguageItemLayer onClick={onKoreanClick}>
                        <img src={KoreanIcon} alt="korean icon"/>
                        <p>한국어</p>
                    </LanguageItemLayer>
                </LanguageSelectionLayer>
                <div style={{height: "270px"}}></div>
                <NextButton onClick={() => setThisPage(1)}>{text.next}</NextButton>
            </ModalBox>
        )
    } else {
        return null
    }
}

function IdSettingPage({ thisPage, setUserIdValue, setThisPage } :Iprops) {
    const [idInput, setIdInput] = useState<string>("")
    const [errMsg, setErrMsg] = useState<string>("")
    const [idList, setIdList] = useState([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [text] = [data.text]

    useEffect(() => {
        const unsub = onSnapshot(doc(getFirestore(), "users", "idList"), async (doc) => {
            if (doc.exists()) {
                setIdList(doc.data().list)
            }
        })

        return () => unsub()
    }, [])

    function idUseableTest(id :string) {
        const regex = new RegExp("^([a-zA-Z\\d._-]{1,16})$")
        return regex.test(id)
    }

    function onIdInputChange(event :any) {
        setIdInput(event.target.value)
        if (idInput.length < 5) {
            setErrMsg("The minimum ID length is 5 characters.")
        } else if (idInput.length > 15) {
            setErrMsg("The maximum ID length is 15 characters.")
        } else if (idList.find((Element: string) => Element === event.target.value) !== undefined) {
            setErrMsg("This ID is using")
        } else if (!idUseableTest(event.target.value)) {
            setErrMsg("You can use only a~z, 0~9, ., _ and -")
        } else {
            setErrMsg("")
        }
    }

    async function onNextButtonClick() {
        if (errMsg === "" && idInput !== "") {
            await updateDoc(doc(getFirestore(), "users", "idList"), {
                list: arrayUnion(idInput)
            })
            setUserIdValue(idInput)
            setThisPage(2)
        } else {
            if (errMsg === "") {
                setErrMsg("Please input id")
            }
        }
    }

    if (thisPage === 1) {
        return (
            <ModalBox>
                <ModalTitle>{text.userIdSetting}</ModalTitle>
                <IdLabel htmlFor="IdSettingInput">@</IdLabel>
                <Input id={"IdSettingInput"} placeholder={text.idInput} type={"text"} onChange={onIdInputChange}></Input>
                <ErrorMessage>{errMsg}</ErrorMessage>
                <div style={{height: "270px"}}></div>
                <NextButton onClick={onNextButtonClick}>{text.next}</NextButton>
            </ModalBox>
        )
    } else {
        return null
    }
}

function SetLocation({thisPage, setThisPage} :Iprops2) {
    const [locationValue, setLocationValue] = useState<object>()
    const [errMsg, setErrMsg] = useState<string>()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]

    function onPlaceChange(place :any) {
        place.address_components.map((e :any) => {
            if (e.types.indexOf("country") > -1) {
                setLocationValue({
                    country: e.short_name,
                    placeId: place.place_id
                })
            }
        })
    }

    async function onNextClick() {
        if (locationValue !== undefined) {
            await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                location: locationValue
            })
            setThisPage(3)
        } else {
            setErrMsg("Please input your location")
        }
    }

    if (thisPage === 2) {
        return (
            <ModalBox>
                <ModalTitle>{text.locationSetting}</ModalTitle>
                <FlexBox>
                    <img src={LocationIcon} alt="location box" width={"50px"}/>
                    <Input as={ReactGoogleAutocomplete} apiKey={"AIzaSyCcX67loAS8ClbEOiTpfn1Zb2ayiBTHftQ"} placeholder={text.inputLocation} onPlaceSelected={(place :object) => onPlaceChange(place)}></Input>
                </FlexBox>
                <ErrorMessage>{errMsg}</ErrorMessage>
                <div style={{height: "270px"}}></div>
                <NextButton onClick={onNextClick}>{text.next}</NextButton>
            </ModalBox>
        )
    } else {
        return null
    }
}

function SetProfile({ thisPage, userIdValue } :ProfileSettingProps) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]
    const [boxBackground, setBoxBackground] = useState<string>("#00000030")
    const [profileBoxBackground, setProfileBoxBackground] = useState<string>("#00000030")
    const [bannerImageFile, setBannerImageFile] = useState<string>("")
    const [profileImageFile, setProfileImageFile] = useState<string>("")
    const [userName, setUserName] = useState<string>(userObj.name)
    const [errMsg, setErrMsg] = useState<string>("")
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [profileMargin, setProfileMargin] = useState("100px")
    const [profilePrivate, setProfilePrivate] = useState(false)
    const {width} = useWindowDimensions()
    const dispatch = useDispatch()

    function dragEnter() {
        setBoxBackground("#00000045")
    }

    function dragLeave() {
        setBoxBackground("#00000030")
    }

    function profileDragEnter() {
        setProfileBoxBackground("#00000045")
    }

    function profileDragLeave() {
        setProfileBoxBackground("#00000030")
    }

    function onBannerFileChange(event :any) {
        const files = event.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = (finishedEvent :any) => {
            const result = finishedEvent.currentTarget.result
            setBannerImageFile(result)
        }
        try{
            reader.readAsDataURL(file)
        } catch (error) {
            console.error(error)
        }
    }

    function onProfileFileChange(event :any) {
        const files = event.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = (finishedEvent :any) => {
            const result = finishedEvent.currentTarget.result
            setProfileImageFile(result)
        }
        try{
            reader.readAsDataURL(file)
        } catch (error) {
            console.error(error)
        }
    }

    function onProfileFileDelete() {
        setProfileImageFile("")
    }

    function onBannerImageFileDelete() {
        setBannerImageFile("")
    }

    function onUserNameChange(event :any) {

        setUserName(event.target.value)
        if (event.target.value.trim() === "") {
            setErrMsg("Place set your name")
        } else {
            setErrMsg("")
        }
    }

    function onProfilePrivateChange(event :any) {
        setProfilePrivate(event.target.checked)
    }

    useEffect(() => {
        if (userObj.name !== undefined) {
            setUserName(userObj.name)
        }
    }, [userObj])


    async function onNextClick() {
        if (errMsg === "") {
            setIsUploading(true)

            console.log(userIdValue)
            await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                id: userIdValue,
                name: userName,
                private: profilePrivate
            })

            await updateDoc(doc(getFirestore(), "users", "searchArray"), {
                list: arrayUnion({
                    id: userIdValue,
                    name: userName
                })
            })

            if (bannerImageFile !== "") {
                uploadString(ref(getStorage(), `${userObj.userId}/${uuidv4()}`), bannerImageFile, "data_url").then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                }).then(async downloadURL => {
                    await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                        bannerPhoto: downloadURL
                    })
                })
            }

            if (profileImageFile !== "") {
                uploadString(ref(getStorage(), `${userObj.userId}/${uuidv4()}`), profileImageFile, "data_url").then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                }).then(async downloadURL => {
                    await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                        profilePhoto: downloadURL
                    })
                })
            }

            dispatch({type: "SET accountSetupComplete", input: true})
        }
    }

    useEffect(() => {
        if (width < 650) {
            setProfileMargin("70px")
        } else {
            setProfileMargin("100px")
        }
    }, [width])

    if (thisPage === 3) {
        return (
            <ModalBox>
                <ModalTitle>{text.profileSetting}</ModalTitle>
                {bannerImageFile !== "" ? (
                    <BannerImagePreviewBox image={bannerImageFile}>
                        <ImageDeleteButton href={"#"} onClick={onBannerImageFileDelete}>
                            <img src={BinIcon} alt="image delete button" width={"25px"}/>
                        </ImageDeleteButton>
                    </BannerImagePreviewBox>
                ) : (
                    <DropContainer type="file" accept={"image/*"} color={boxBackground} onDragEnter={dragEnter} onDragLeave={dragLeave} onChange={onBannerFileChange}/>
                )}
                {profileImageFile !== "" ? (
                    <ProfileImagePreviewBox>
                        <ImageDeleteButton href={"#"} onClick={onProfileFileDelete}>
                            <img src={BinIcon} alt="image delete button" width={"25px"}/>
                        </ImageDeleteButton>
                        <ProfileImagePreview image={profileImageFile}/>
                    </ProfileImagePreviewBox>
                ) : (
                    <ProfileImageInput type="file" accept={"image/*"} color={profileBoxBackground} onDragEnter={profileDragEnter} onDragLeave={profileDragLeave} onChange={onProfileFileChange}/>
                )}
                <div style={{height: profileMargin}}></div>
                <Input type="text" defaultValue={userObj.name} style={{textAlign: "center"}} onChange={onUserNameChange} maxLength={15}/>
                <p style={{color: "#7c7c7c", fontSize: "15px", margin: "0"}}>{"@" + userIdValue}</p>
                <AlineLeft>
                    <input type="checkbox" id={"private"} onChange={onProfilePrivateChange}/>
                    <label htmlFor="private">
                        {text.profilePrivate}
                    </label>
                </AlineLeft>
                <ErrorMessage>{errMsg}</ErrorMessage>
                {isUploading ? (
                    <p>{text.uploading}</p>
                ) : (
                    <NextButton onClick={onNextClick}>{text.next}</NextButton>
                )}
            </ModalBox>
        )
    } else {
        return null
    }
}

function AccountSetup() {
    const [userIdValue, setUserIdValue] = useState<string>("")
    const [thisPage, setThisPage] = useState<number>(0)
    const data = useSelector((state => state))
    // @ts-ignore
    const [accountSetupComplete] = [data.accountSetupComplete]

    if (accountSetupComplete) {
        return null
    } else {
        return (
            <ModalOverBox>
                <ModalOverlay/>
                <AccountSetupBox>
                    <LanguageSettingPage thisPage={thisPage} setThisPage={setThisPage}/>
                    <IdSettingPage thisPage={thisPage} setThisPage={setThisPage} setUserIdValue={setUserIdValue}/>
                    <SetLocation thisPage={thisPage} setThisPage={setThisPage}/>
                    <SetProfile thisPage={thisPage} userIdValue={userIdValue}/>
                </AccountSetupBox>
            </ModalOverBox>
        )
    }
}

export default AccountSetup