import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {format} from "timeago.js";

import MainSideContent from "./modules/mainSideContent";
import NewMessage from "./modals/newMessage";
import {collection, getFirestore, where, query, onSnapshot, doc, getDoc} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {getDatabase, onValue, ref, serverTimestamp, set} from "firebase/database";

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 140px;
    text-align: center;
  
    @media screen and (max-width: 1118px) {
        margin: 90px auto 0;
    }
  
    @media screen and (max-height: 750px) {
        margin: 90px auto 0;
    }
  
    @media screen and (max-width: 850px) {
        width: 620px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
    }
`

const NewMessageButton = styled.a`
    background-color: #00d2ff;
    font-size: 20px;
    padding: 10px 304px;
    border-radius: 10px;
  
    @media screen and (max-width: 850px) {
        padding: 10px 235px;
    }
  
    @media screen and (max-width: 620px) {
        padding: 10px 107px;
    }
`

const ChatListLayer = styled.div`
    margin: 35px auto;
    width: 750px;
  
    @media screen and (max-width: 850px) {
        width: 620px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
    }
`

const ChatLayer = styled.a`
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  
    @media screen and (max-width: 850px) {
        width: 620px;
    }
  
    @media screen and (max-width: 620px) {
        width: 360px;
    }
`

const ChatNameLayer = styled.div`
    display: flex;
    align-items: center;
  
    div {
        text-align: left;
    }
  
    h3 {
        margin: 0;
        font-size: 20px;
        font-weight: normal;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
  
    @media screen and (max-width: 850px) {
        h3 {
            width: 400px;
        }
    }
  
    @media screen and (max-width: 620px) {
        h3 {
            width: 150px;
        }
    }
`

const LastChat = styled.p<{isUnchecked :boolean}>`
    margin: 0;
    color: ${props => props.isUnchecked ? props.theme.color : `#7c7c7c`};
    font-weight: ${props => props.isUnchecked ? `bolder` : `normal`};
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 500px;

    @media screen and (max-width: 850px) {
        width: 380px;
    }
  
    @media screen and (max-width: 400px) {
        width: 400px;
    }
  
    @media screen and (max-width: 620px) {
        font-size: 13px;
        width: 150px;
    }
`

const LastChatTime = styled.p`
    margin: 20px;
    text-align: right;
   
    @media screen and (max-width: 620px) {
        font-size: 13px;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 60px;
    height: 60px;
    border-radius: 90px;
    margin: 15px 10px 15px 25px;

    @media screen and (max-width: 620px) {
        width: 50px;
        height: 50px;
        margin-left: 15px;
    }
`

const OnlineLight = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(67px, 21px);

    @media screen and (max-width: 620px) {
        transform: translate(50px, 17px);
        width: 15px;
        height: 15px;
    }
`

const UncheckedLight = styled.div`
    width: 13.5px;
    height: 25px;
    background-color: #00d2ff;
    position: absolute;
    border-radius: 0 90px 90px 0;
  
    @media screen and (max-width: 620px) {
        height: 20px;
        width: 10px;
    }
`

const ChatImageLayer = styled.div`
    width: 60px;
    height: 60px;
    margin: 15px 10px 15px 25px;
    position: relative;
    
    @media screen and (max-width: 620px) {
        width: 50px;
        height: 50px;
        margin-left: 15px;
    }
`

const FirstImage = styled.div<{image :string}>`
    width: 42px;
    height: 42px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 90px;
  
    @media screen and (max-width: 620px) {
        width: 35px;
        height: 35px;
    }
`

const SecondImage = styled(FirstImage)`
    right: 0;
    bottom: 0;
    left: auto;
    top: auto;
`

const Margin = styled.div`
    height: 35px;
`

interface ChatProps {
    chatObj :any
}

function Chat({chatObj} :ChatProps) {
    const [chatName, setChatName] = useState<string>("")
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [chatImageArray, setChatImageArray] = useState<string[]>([])
    const [isUnchecked, setIsUnchecked] = useState<boolean>(false)

    const navigate = useNavigate()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        if (chatObj.chatType === "personal") {
            const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
            const getProfile = onSnapshot(doc(getFirestore(), "users", userId[0]), (doc) => {
                if (doc.exists()) {
                    setChatName(doc.data().name)
                    setChatImageArray([doc.data().profilePhoto])
                }
            })

            const getOnlineStatus = onValue(ref(getDatabase(), `status/${userId[0]}/state`), (snapshot) => {
                if (snapshot.exists() && snapshot.val() === "online") {
                    setIsOnline(true)
                } else {
                    setIsOnline(false)
                }
            })

            return(() => {
                getProfile()
                getOnlineStatus()
            })
        } else if (chatObj.chatType === "group") {
            if (chatObj.chatImage === "default") {
                const imageArray :string[] = []
                const userId = chatObj.member.filter((e :string) => e !== userObj.userId)
                setTimeout(() => {
                    userId.map((e :string) => {
                        getDoc(doc(getFirestore(), "users", e)).then((doc) => {
                            if (doc.exists()) {
                                imageArray.push(doc.data().profilePhoto)
                                setChatImageArray(imageArray)
                            }
                        })
                    })
                }, 300)
            } else {
                setChatImageArray(chatObj.chatImage)
            }

            if (typeof chatObj.chatName === "string") {
                setChatName(chatObj.chatName)
            } else {
                setChatName(chatObj.chatName.toString())
            }
        }
    }, [userObj, chatObj])

    useEffect(() => {
        getDoc(doc(getFirestore(), `chats/${chatObj.chatId}/lastCheck`, userObj.userId)).then((doc) => {
            if (doc.exists()) {
                if (doc.data().lastCheckTime.seconds <= chatObj.lastChatTime.seconds) {
                    setIsUnchecked(true)
                } else {
                    setIsUnchecked(false)
                }
            } else {
                setIsUnchecked(true)
            }
        })
    }, [chatImageArray])

    return (
        <ChatLayer onClick={() => navigate(`/chat/${chatObj.chatId}`)}>
            <ChatNameLayer>
                {isUnchecked ? (
                    <UncheckedLight/>
                ) : null}
                {chatImageArray.length === 1 ? (
                    <>
                        <ProfileImage image={chatImageArray[0]}/>
                        {isOnline ? (
                            <OnlineLight/>
                        ) : null}
                    </>
                ) : (
                    <ChatImageLayer>
                        <FirstImage image={chatImageArray[0]}/>
                        <SecondImage image={chatImageArray[1]}/>
                    </ChatImageLayer>
                )}
                <div>
                    <h3>{chatName}</h3>
                    <LastChat isUnchecked={isUnchecked}>{chatObj.lastChat}</LastChat>
                </div>
            </ChatNameLayer>
            <LastChatTime>{format(chatObj.lastChatTime.seconds * 1000)}</LastChatTime>
        </ChatLayer>
    )
}

function Main() {
    const [showNewMessage, setShowNewMessage] = useState<boolean>(false)
    const [chatList, setChatList] = useState<object[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        if (userObj.userId !== undefined) {
            const getChatQuery = query(collection(getFirestore(), "chats"), where("member", "array-contains", userObj.userId))
            const getChat = onSnapshot(getChatQuery, (querySnapshot) => {
                const chatArray :object[] = []
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        chatArray.push({
                            ...doc.data(),
                            chatId: doc.id
                        })
                    }
                })
                setChatList(chatArray.sort((a: any, b: any) => parseFloat(b.lastChatTime.seconds) - parseFloat(a.lastChatTime.seconds)))
            })

            set(ref(getDatabase(), `status/${userObj.userId}`), {
                state: "online",
                last_changed: serverTimestamp(),
                page: "main"
            }).then(r => r)

            const metaTag = document.querySelector("meta[name='theme-color']")
            if (metaTag instanceof HTMLMetaElement) {
                metaTag.setAttribute("content", "#000000")
            }

            return(() => getChat())
        }
    }, [userObj])

    return (
        <MainPage>
            <NewMessage showNewMessage={showNewMessage} setShowNewMessage={setShowNewMessage}/>
            <MainContent>
                <NewMessageButton onClick={() => setShowNewMessage(true)}>New message</NewMessageButton>
                <ChatListLayer>
                    {chatList.map((e :any, i) => (
                        <Chat chatObj={e} key={i}/>
                    ))}
                </ChatListLayer>
                <Margin/>
            </MainContent>
            <MainSideContent/>
        </MainPage>
    )
}

export default Main