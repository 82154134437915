import React from "react";
import styled from "styled-components";

const PhotoModal = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    position: fixed;
    z-index: 10000;
  
    @media screen and (max-width: 1000px) {
        background-color: rgba(0, 0, 0, 0.6);
    }
`

const PhotoContent = styled.div<{image :string}>`
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

interface ImageViewerProps {
    showImageViewer :boolean,
    setShowImageViewer :(active :boolean) => void,
    imageContent :string
}

function ImageViewer({showImageViewer, setShowImageViewer, imageContent} :ImageViewerProps) {
    if (showImageViewer) {
        return (
            <PhotoModal>
                <PhotoContent onClick={() => setShowImageViewer(false)} image={imageContent}/>
            </PhotoModal>
        )
    } else {
        return null
    }
}

export default ImageViewer