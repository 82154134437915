import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MainSideContent from "./modules/mainSideContent";
import {useLocation} from "react-router-dom";
import {addDoc, collection, deleteDoc, doc, getFirestore, onSnapshot, query, setDoc, where} from "firebase/firestore";
import {useSelector} from "react-redux";
import Profile from "./modals/profile";

const FollowIcon = require("../images/icons/follow.png")
const FollowingIcon = require("../images/icons/following.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin: 125px 50px 0;

    @media screen and (max-width: 1118px) {
        margin: 90px auto 0;
    }
    
    @media screen and (max-height: 750px) {
        margin: 90px auto 0;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const UserSearchLayer = styled.div`
    width: 750px;
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    margin: 0 auto 30px;
    padding-bottom: 20px;
    background-color: ${props => props.theme.backgroundColor};
  
    @media screen and (max-width: 850px) {
        width: 600px;
    }
  
    @media screen and (max-width: 620px) {
        width: 350px;
    }
`

const UserSearchResultLayer = styled.div`
    display: flex;
    width: 500px;
    margin: 0 auto;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;

    img {
        border-radius: 90px;
    }
    
    h2 {
        font-weight: bolder;
        margin: 0 0 0 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: ${props => props.theme.color};
    }

    @media screen and (max-width: 850px) {
        width: 550px;
        margin-left: 25px;
    }
    
    @media screen and (max-width: 620px) {
        width: 300px;
        
        h2 {
            font-weight: normal;
            font-size: 20px;
            width: 120px;
        }
    }
`

const TagInfoLayer = styled.a`
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
`

const FollowButton = styled.a`
    cursor: pointer;
    background-color: #00d2ff;
    padding: 7px 15px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    
    p {
        margin: 0 5px;
    }
    
    @media screen and (max-width: 620px) {
        font-size: 13px;
        padding: 7px 13px;
        
        p {
            margin: 0 2px;
        }
        
        img {
            width: 20px;
        }
    }
`

const FollowingButton = styled(FollowButton)`
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    border: 3px solid #00d2ff;
    padding: 4px 7px;

    @media screen and (max-width: 620px) {
        border: 2px solid #00d2ff;
    }
`

interface UserSearchProfileProps {
    userId :string,
    followingArray :string[]
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function UserSearchProfile({userId, followingArray} :UserSearchProfileProps) {
    const [profile, setProfile] = useState<any>()
    const [isFollowing, setIsFollowing] = useState<boolean>(false)
    const [showProfile, setShowProfile] = useState<boolean>(false)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getProfileQuery = query(collection(getFirestore(), "users"), where("id", "==", userId))
        const getProfile = onSnapshot(getProfileQuery, (querySnapshot) => {
            const profileArray :object[] = []
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    profileArray.push(doc.data())
                }
            })
            setProfile(profileArray[0])
        })

        return (() => getProfile())
    }, [userId])

    useEffect(() => {
        if (profile !== undefined) {
            if (followingArray.indexOf(profile.userId) > -1) {
                setIsFollowing(true)
            } else {
                setIsFollowing(false)
            }
        }
    }, [profile, followingArray])

    async function onFollowClick() {
        if (!isFollowing) {
            await addDoc(collection(getFirestore(), `users/${profile.userId}/notifications`), {
                content: `${userObj.name} started following you`,
                date: new Date(),
                isChecked: false,
                redirection: `/profile/${userObj.id}`,
                from: userObj.userId
            })

            await setDoc(doc(getFirestore(), `users/${profile.userId}/followers`, userObj.userId), {
                userId: userObj.userId,
                followedAt: new Date()
            })

            await setDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.userId), {
                userId: profile.userId,
                followedAt: new Date()
            })
        }
    }

    async function onFollowingClick() {
        if (isFollowing) {
            await deleteDoc(doc(getFirestore(), `users/${profile.userId}/followers`, userObj.userId))
            await deleteDoc(doc(getFirestore(), `users/${userObj.userId}/followings`, profile.userId))
        }
    }

    if (profile !== undefined) {
        return (
            <UserSearchResultLayer>
                <Profile showProfile={showProfile} setShowProfile={setShowProfile} profile={profile}/>
                <TagInfoLayer onClick={() => setShowProfile(true)}>
                    <ProfileImage image={profile.profilePhoto}/>
                    <h2>{profile.name}</h2>
                </TagInfoLayer>
                {profile.userId !== userObj.userId && !isFollowing ? (
                    <FollowButton onClick={onFollowClick}>
                        <img src={FollowIcon} alt="follow icon" width={"30px"}/>
                        <p>Follow</p>
                    </FollowButton>
                ) : profile.userId !== userObj.userId && isFollowing ? (
                    <FollowingButton onClick={onFollowingClick}>
                        <img src={FollowingIcon} alt="following icon" width={"30px"}/>
                        <p>Following</p>
                    </FollowingButton>
                ) : null}
            </UserSearchResultLayer>
        )
    } else {
        return null
    }
}

function Search() {
    const query1 = useQuery()
    const keyWord = query1.get("q")
    const [userSearchArray, setUserSearchArray] = useState<object[]>([])
    const [followingArray, setFollowingArray] = useState<string[]>([])

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getUserSearchArray = onSnapshot(doc(getFirestore(), "users", "searchArray"), (doc: any) => {
            setUserSearchArray(doc.data().list.filter((e :any) => e.name.search(keyWord) > -1))
        })

        const getFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            const followingArray :string[] = []
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    followingArray.push(doc.data().userId)
                }
            })

            setFollowingArray(followingArray)
        })

        return(() => {
            getUserSearchArray()
            getFollowing()
        })
    }, [keyWord])

    return (
        <MainPage>
            <MainContent>
                {userSearchArray.length !== 0 ? (
                    <UserSearchLayer>
                        {userSearchArray.map((e :any, i) => (
                            <UserSearchProfile userId={e.id} followingArray={followingArray} key={i}/>
                        ))}
                    </UserSearchLayer>
                ) : null}
            </MainContent>
            <MainSideContent/>
        </MainPage>
    )
}

export default Search