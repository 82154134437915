import React, {useEffect, useState} from 'react';
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "@firebase/auth"
import {getDoc, doc, getFirestore, setDoc, onSnapshot, collection, updateDoc, arrayUnion} from "firebase/firestore"
import { getDatabase, ref, set, serverTimestamp, onValue, onDisconnect } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import {ThemeProvider} from "styled-components";
import Navbar from "./router/modules/navbar";
import Login from "./router/login";
import Main from "./router/main";
import Following from "./router/following";
import MainSideBar from "./router/modules/mainSideBar";
import Setting from "./router/setting";
import Chat from "./router/chat";
import ChatSideBar from "./router/modules/chatSideBar";
import NotificationPage from "./router/notification";
import Explore from "./router/explore";

import './style/App.css';
import NotificationSetting from "./router/settings/notificationSetting";
import LocationSetting from "./router/settings/locationSetting";
import ProfileSetting from "./router/settings/profileSetting";
import DarkModeSetting from "./router/settings/darkModeSetting";
import LanguageSetting from "./router/settings/languageSetting";

import en from "./languages/en.json"
import ko from "./languages/ko.json"
import useWindowDimensions from "./router/modules/getDisplaySize";
import Search from "./router/search";

const firebaseConfig = {
    apiKey: "AIzaSyCG8L4vH4SoY6XdDcv4RsE8HIx8csUSt1I",
    authDomain: "a20201126.firebaseapp.com",
    databaseURL: "https://a20201126.firebaseio.com",
    projectId: "a20201126",
    storageBucket: "a20201126.appspot.com",
    messagingSenderId: "1023670349812",
    appId: "1:1023670349812:web:85b27869be6094e20fd3a1",
    measurementId: "G-6FDQVHBL4Q"
}

const app = initializeApp(firebaseConfig)
getDatabase(app)
getAnalytics(app)

const LightTheme = {
    backgroundColor: "white",
    color: "black",
    boxShadow: "1px 4px 10px #00000028",
    hover: "whitesmoke",
    themeColor: "white"
}

const DarkTheme = {
    backgroundColor: "#202020",
    color: "white",
    boxShadow: "none",
    hover: "#1b1b1b",
    themeColor: "black"
}

const FlexBox = styled.div `
    display: flex;
    height: 100%;
`

const ChatFlexBox = styled(FlexBox)`
    @media screen and (max-width: 1118px) {
        width: 98%;
    }
`

function App() {
    const [theme, setTheme] = useState<object>(LightTheme)
    const [token, setToken] = useState<string>("")
    const {width, height} = useWindowDimensions()

    const auth = getAuth()
    const dispatch = useDispatch()
    const data = useSelector((state => state))
    // @ts-ignore
    const [isLoggedIn, isDarkMode, userObj] = [data.isLoggedIn, data.isDarkMode, data.userObj]

    //account setup
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idList = await getDoc(doc(getFirestore(), "users", "idList"))
                dispatch({type: "SET isLoggedIn", input: true})

                const unsub = onSnapshot(doc(getFirestore(), "users", user.uid), async (profileDoc) => {
                    if (profileDoc.exists()) {
                        if (idList.exists()) {
                            if (idList.data().list.find((Element: string) => Element === profileDoc.data().id)) {
                                dispatch({type: "SET accountSetupComplete", input: true})
                            } else {
                                dispatch({type: "SET accountSetupComplete", input: false})
                            }
                        }
                        dispatch({type: "SET userObj", input: profileDoc.data()})
                    } else {
                        dispatch({type: "SET accountSetupComplete", input: false})

                        await setDoc(doc(collection(getFirestore(), "users"), user.uid), {
                            createdAt: new Date(),
                            email: user.email,
                            id: user.uid,
                            name: user.displayName,
                            profilePhoto: user.photoURL,
                            userId: user.uid
                        })

                        dispatch({
                            type: "SET userObj", input: {
                                createdAt: new Date(),
                                email: user.email,
                                id: user.uid,
                                name: user.displayName,
                                profilePhoto: user.photoURL,
                                userId: user.uid
                            }
                        })
                    }
                })

                return () => unsub()
            } else {
                dispatch({type: "SET isLoggedIn", input: false})
            }
        })
    }, [])

    useEffect(() => {
        const getLanguage = onSnapshot(doc(getFirestore(), `users/${userObj.userId}/setting`, "language"), (doc) => {
            if (doc.exists()) {
                if (doc.data().language === "english") {
                    dispatch({type: "SET text", input: {...en}})
                } else if (doc.data().language === "korean") {
                    dispatch({type: "SET text", input: {...ko}})
                }
            }
        })

        return (() => getLanguage())
    }, [userObj])

    useEffect(() => {
        const getDarkModeSetting = onSnapshot(doc(getFirestore(), `users/${userObj.userId}/setting`, "darkMode"), (doc) => {
            if (doc.exists()) {
                if (doc.data().darkMode !== undefined) {
                    dispatch({type: "SET isDarkMode", input: doc.data().darkMode})
                    localStorage.setItem("darkmode", doc.data().darkMode)
                }
            }
        })

        return(() => getDarkModeSetting())
    }, [userObj])

    useEffect(() => {
        if (localStorage.getItem("darkmode") === "true") {
            dispatch({type: "SET isDarkMode", input: true})
        } else {
            dispatch({type: "SET isDarkMode", input: false})
        }
    }, [])

    useEffect(() => {
        if (isDarkMode) {
            const body = document.querySelector("body")
            if (body instanceof HTMLBodyElement) {
                body.style.backgroundColor = "black"
            }

            setTheme(DarkTheme)
        } else {
            const body = document.querySelector("body")
            if (body instanceof HTMLBodyElement) {
                body.style.backgroundColor = "white"
            }

            setTheme(LightTheme)
        }
    }, [isDarkMode])

    useEffect(() => {
        if (token !== "") {
            updateDoc(doc(getFirestore(), "users", userObj.userId), {
                chatToken: arrayUnion(token)
            }).then(r => r)

            dispatch({type: "SET token", input: token})
        }
    }, [token])

    if (navigator.userAgent.match(/inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|Whale|kakaostory|band|twitter|DaumApps|DaumDevice|Facebook|FB|facebook/i) && navigator.vendor.indexOf("Apple") < 0) {
        window.location.href='intent://tunip.chat#Intent;scheme=http;package=com.android.chrome;end'
    } else {
        if (navigator.vendor.indexOf("Apple") < 0) {
            if (window.location.protocol === "https:" || window.location.hostname === "localhost") {
                const messaging = getMessaging(app)
                getToken(messaging, {vapidKey: "BFsfBZmjdfC2QB5gDy-cUzAsmI04X1-XPfJ3vnfGAJ7DmbP2SbaOAUoXBdl72t3sApyNX8j8tGVqRm6MhvyjJaI"}).then((currentToken) => {
                    if (currentToken !== undefined && userObj.userId !== undefined) {
                        setToken(currentToken)
                    }
                }).catch(e => console.error(e))

                onMessage(messaging, (payload) => {
                    console.log(payload);
                });

                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                    }
                })
            }
        }
    }

    function rtdb_and_local_fs_presence() {
        const uid = userObj.userId;
        const userStatusDatabaseRef = ref(getDatabase(), "/status/" + uid)

        const isOfflineForDatabase = {
            state: 'offline',
            last_changed: serverTimestamp(),
        };

        const isOnlineForDatabase = {
            state: 'online',
            last_changed: serverTimestamp(),
        };

        const userStatusFirestoreRef = ref(getDatabase(), "/status/" + uid);
        const isOfflineForFirestore = {
            state: 'offline',
            last_changed: serverTimestamp(),
        };

        const isOnlineForFirestore = {
            state: 'online',
            last_changed: serverTimestamp(),
        };

        onValue(ref(getDatabase(), ".info/connected"), (snapshot) => {
            if (snapshot.val() == false) {
                set(userStatusFirestoreRef, isOfflineForFirestore).then(r => r)
                return;
            }

            onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(() => {
                set(userStatusDatabaseRef, isOnlineForDatabase).then(r => r)
                set(userStatusFirestoreRef, isOnlineForFirestore).then(r => r)
            })
        })
    }

    rtdb_and_local_fs_presence()

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        {!isLoggedIn ? (
                            <Route path={"/"} element={
                                <div className={"App"}>
                                    <Login/>
                                </div>
                            }/>
                        ) : (
                            <>
                                <Route path={"/"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <Main/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/chat/:chatId"} element={
                                    <>
                                        {width > 1360 && height > 950 ? (
                                            <Navbar/>
                                        ) : null}
                                        <ChatFlexBox>
                                            <ChatSideBar/>
                                            <Chat/>
                                        </ChatFlexBox>
                                    </>
                                }/>
                                <Route path={"/following"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <Following/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/notification"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <NotificationPage/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/explore"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <Explore/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/search"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <Search/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/setting"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <Setting/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/setting/profile"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <ProfileSetting/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/setting/language"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <LanguageSetting/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/setting/location"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <LocationSetting/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/setting/notification"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <NotificationSetting/>
                                        </FlexBox>
                                    </>
                                }/>
                                <Route path={"/setting/darkmode"} element={
                                    <>
                                        <Navbar/>
                                        <FlexBox>
                                            <MainSideBar/>
                                            <DarkModeSetting/>
                                        </FlexBox>
                                    </>
                                }/>
                            </>
                        )}
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
