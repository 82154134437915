import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SideContent from "../modules/mainSideContent"
import {useSelector} from "react-redux";
import {arrayRemove, arrayUnion, doc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";
import {deleteObject, getDownloadURL, getStorage, ref, uploadString} from "firebase/storage";
import {v4 as uuidv4} from "uuid";

const BinIcon = require("../../images/icons/bin.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
    text-align: center;
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const DropContainer = styled.input`
    background-color: ${props => props.color};
    border-radius: 10px;
    box-shadow: 1px 4px 10px #00000028;
    appearance: none;
    padding: 60px 200px;
    width: 200px;

    @media screen and (max-width: 620px) {
        padding: 35px 75px;
    }
`

const BannerImagePreviewBox = styled.div<{image :string}>`
    width: 600px;
    height: 142.5px;
    background-image: url(${props => props.image});
    background-color: ${props => props.theme.backgroundColor};
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 1px 4px 10px #00000028;

    @media screen and (max-width: 620px) {
        width: 350px;
        height: 100px;
    }
`

const ImageDeleteButton = styled.a`
    float: left;
    background-color: #00000060;
    border-radius: 90px;
    margin: 10px;
    padding-left: 1.5px;
    padding-right: 1.5px;
`

const ProfileImageInput = styled(DropContainer)`
    padding: 64px 0;
    border: 0;
    border-radius: 120px;
    width: 150px;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, 70%);
  
    @media screen and (max-width: 620px) {
        transform: translate(-50%, 0%);
        padding: 45px 0;
        width: 110px;
    }
`

const ProfileImagePreviewBox = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 120px;
    box-shadow: 1px 4px 10px #00000028;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, 70%);
    background-color: white;

    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
        transform: translate(-50%, 10%);
    }
`

const ProfileImagePreview = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 110px;
        height: 110px;
    }
`

const Input = styled.input`
    width: 300px;
    height: 35px;
    font-size: 30px;
    border: 0;
    border-bottom: 3px solid ${props => props.theme.color};
    outline: none;
    margin: 0 auto;
    color: ${props => props.theme.color};
    background-color: #00000000;
`

const Margin = styled.div`
    height: 20px;
  
    @media screen and (max-width: 620px) {
        display: none;
    }
`

const ToggleButton = styled.label`
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 35px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-right: 20px;
  
    &:after {
        content: '';
        position: absolute;
        top: 4.4px;
        left: 5px;
        width: 27px;
        height: 27px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    &:active:after {
        width: 35px;
    }
`

const SettingMenu = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto 0;
    box-shadow: ${props => props.theme.boxShadow};
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.backgroundColor};
    border-radius: 10px;
  
    p {
        margin: 20px;
        font-size: 20px;
    }
  
    input {
        visibility: hidden;
    }

    input:checked + label {
        background: #00d2ff;
    }

    input:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }
  
    @media screen and (max-width: 620px) {
        width: 98%;
      
        p {
            font-size: 19px;
        }
    }
`

const BottomMargin = styled.div`
    height: 70px;
`

function ProfileSetting() {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]
    const [boxBackground, setBoxBackground] = useState<string>("#00000030")
    const [profileBoxBackground, setProfileBoxBackground] = useState<string>("#00000030")
    const [bannerImageFile, setBannerImageFile] = useState<string>("")
    const [profileImageFile, setProfileImageFile] = useState<string>("")
    const [userSearchArray, setUserSearchArray] = useState<object[]>([])

    function dragEnter() {
        setBoxBackground("#00000045")
    }

    function dragLeave() {
        setBoxBackground("#00000030")
    }

    function profileDragEnter() {
        setProfileBoxBackground("#00000045")
    }

    function profileDragLeave() {
        setProfileBoxBackground("#00000030")
    }

    function onBannerFileChange(event :any) {
        const files = event.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = async (finishedEvent :any) => {
            const result = finishedEvent.currentTarget.result
            try {
                await deleteObject(ref(getStorage(), userObj.bannerPhoto)).then(r => r)
            } finally {
                await uploadString(ref(getStorage(), `${userObj.userId}/${uuidv4()}`), result, "data_url").then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                }).then(async downloadURL => {
                    await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                        bannerPhoto: downloadURL
                    })

                    setBannerImageFile(downloadURL)
                })
            }
        }
        try{
            reader.readAsDataURL(file)
        } catch (error) {
            console.error(error)
        }
    }

    function onProfileFileChange(event :any) {
        const files = event.target.files
        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = async (finishedEvent :any) => {
            const result = finishedEvent.currentTarget.result
            try {
                await deleteObject(ref(getStorage(), userObj.profilePhoto)).then(r => r)
            } finally {
                await uploadString(ref(getStorage(), `${userObj.userId}/${uuidv4()}`), result, "data_url").then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                }).then(async downloadURL => {
                    await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                        profilePhoto: downloadURL
                    })
                    setProfileImageFile(result)
                })
            }
        }
        try{
            reader.readAsDataURL(file)
        } catch (error) {
            console.error(error)
        }
    }

    function onProfileFileDelete() {
        setProfileImageFile("")
    }

    function onBannerImageFileDelete() {

        setBannerImageFile("")
    }

    async function onUserNameChange(event :any) {
        if (event.target.value.trim() !== "" && event.target.value.length < 16) {
            await updateDoc(doc(getFirestore(), "users", "searchArray"), {
                list: arrayRemove(userSearchArray.find((e :any) => e.id === userObj.id))
            })

            await updateDoc(doc(getFirestore(), "users", "searchArray"), {
                list: arrayUnion({
                    id: userObj.id,
                    name: event.target.value
                })
            })

            await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                name: event.target.value
            })
        }
    }

    async function onPrivateChange(event :any) {
        await updateDoc(doc(getFirestore(), "users", userObj.userId), {
            private: event.target.checked
        })
    }

    useEffect(() => {
        if (userObj.userId !== undefined) {
            const getProfile = onSnapshot(doc(getFirestore(), "users", userObj.userId), (doc) => {
                if (doc.exists()) {
                    setBannerImageFile(doc.data().bannerPhoto)
                    setProfileImageFile(doc.data().profilePhoto)
                }
            })

            const getSearchArray = onSnapshot(doc(getFirestore(), "users", "searchArray"), (doc) => {
                if (doc.exists()) {
                    setUserSearchArray(doc.data().list)
                }
            })

            return(() => {
                getProfile()
                getSearchArray()
            })
        }
    }, [userObj])

    return (
        <MainPage>
            <MainContent>
                <PageTitle>{text.profileSetting}</PageTitle>
                {bannerImageFile !== "" ? (
                    <BannerImagePreviewBox image={bannerImageFile}>
                        <ImageDeleteButton href={"#"} onClick={onBannerImageFileDelete}>
                            <img src={BinIcon} alt="image delete button" width={"25px"}/>
                        </ImageDeleteButton>
                    </BannerImagePreviewBox>
                ) : (
                    <DropContainer type="file" accept={"image/*"} color={boxBackground} onDragEnter={dragEnter} onDragLeave={dragLeave} onChange={onBannerFileChange}/>
                )}
                {profileImageFile !== "" ? (
                    <ProfileImagePreviewBox>
                        <ImageDeleteButton href={"#"} onClick={onProfileFileDelete}>
                            <img src={BinIcon} alt="image delete button" width={"25px"}/>
                        </ImageDeleteButton>
                        <ProfileImagePreview image={profileImageFile}/>
                    </ProfileImagePreviewBox>
                ) : (
                    <ProfileImageInput type="file" accept={"image/*"} color={profileBoxBackground} onDragEnter={profileDragEnter} onDragLeave={profileDragLeave} onChange={onProfileFileChange}/>
                )}
                <Margin/>
                <div style={{height: "70px"}}></div>
                <Input type="text" defaultValue={userObj.name} style={{textAlign: "center"}} onChange={onUserNameChange} maxLength={15}/>
                <p style={{color: "#7c7c7c", fontSize: "15px", margin: "0"}}>{"@" + userObj.id}</p>
                <Margin/>

                <SettingMenu>
                    <p>{text.profilePrivate}</p>
                    <input type="checkbox" id="comment-switch" onChange={onPrivateChange} checked={userObj.private}/>
                    <ToggleButton htmlFor="comment-switch">Toggle</ToggleButton>
                </SettingMenu>
                <BottomMargin/>
            </MainContent>
            <SideContent/>
        </MainPage>
    )
}

export default ProfileSetting