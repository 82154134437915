import React from "react";
import SideContent from "../modules/mainSideContent";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {doc, getFirestore, setDoc} from "firebase/firestore";

const EnglishIcon = require("../../images/icons/english.png")
const KoreanIcon = require("../../images/icons/korean.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
    text-align: center;

    @media screen and (max-width: 1118px) {
        margin: 90px auto 0;
    }
    
    @media screen and (max-height: 750px) {
        margin: 90px auto 0;
    }
    
    @media screen and (max-width: 850px) {
        width: 620px;
    }
    
    @media screen and (max-width: 620px) {
        width: 360px;
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const LanguageSelectionLayer = styled.div`
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  
    @media screen and (max-width: 620px) {
        width: 350px;
    }
`

const LanguageItemLayer = styled.a`
    margin: 20px;
    display: flex;
    align-items: center;
  
    img {
        width: 60px;
    }
  
    p {
        font-size: 20px;
        margin: 10px;
        color: ${props => props.theme.color}
    }
  
    @media screen and (max-width: 620px) {
        img {
            width: 45px;
        }
    }
`

function LanguageSetting() {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, text] = [data.userObj, data.text]

    async function onEnglishClick() {
        await setDoc(doc(getFirestore(), `users/${userObj.userId}/setting`, "language"), {
            language: "english"
        })
    }

    async function onKoreanClick() {
        await setDoc(doc(getFirestore(), `users/${userObj.userId}/setting`, "language"), {
            language: "korean"
        })
    }

    return (
        <MainPage>
            <MainContent>
                <PageTitle>{text.languageSetting}</PageTitle>
                <LanguageSelectionLayer>
                    <LanguageItemLayer onClick={onEnglishClick}>
                        <img src={EnglishIcon} alt="english icon"/>
                        <p>English</p>
                    </LanguageItemLayer>
                    <LanguageItemLayer onClick={onKoreanClick}>
                        <img src={KoreanIcon} alt="korean icon"/>
                        <p>한국어</p>
                    </LanguageItemLayer>
                </LanguageSelectionLayer>
            </MainContent>
            <SideContent/>
        </MainPage>

    )
}

export default LanguageSetting