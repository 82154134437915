import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SideContent from "../modules/mainSideContent";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {useSelector} from "react-redux";
import {doc, getFirestore, updateDoc} from "firebase/firestore";
const LocationIcon = require("../../images/icons/country.png")
const DarkLocationIcon = require("../../images/icons/dark/dark-country.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
    text-align: center;
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
  
    @media screen and (max-width: 850px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const Input = styled.input`
    width: 300px;
    height: 35px;
    font-size: 30px;
    border: 0;
    border-bottom: 3px solid ${props => props.theme.color};
    background-color: #00000000;
    color: ${props => props.theme.color};
    outline: none;
`

const FlexBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

function LocationSetting() {
    const [locationValue, setLocationValue] = useState<object>()
    const [locationName, setLocationName] = useState<string>()
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj, text, isDarkMode] = [data.userObj, data.text, data.isDarkMode]

    function onPlaceChange(place :any) {
        place.address_components.map((e :any) => {
            if (e.types.indexOf("country") > -1) {
                setLocationValue({
                    country: e.short_name,
                    placeId: place.place_id
                })
            }
        })
    }

    useEffect(() => {
        if (userObj.location !== undefined) {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${userObj.location.placeId}&key=AIzaSyCcX67loAS8ClbEOiTpfn1Zb2ayiBTHftQ`, {
                method: 'GET'
            }).then((response) => {
                if (response !== undefined) {
                    return response.json()
                } else {
                    return null
                }
            }).then((json) => {
                if (json !== null) {
                    setLocationName(json.results[0].formatted_address)
                }
            })
        }
    }, [userObj])

    useEffect(() => {
        if (locationValue !== undefined) {
            updateDoc(doc(getFirestore(), "users", userObj.userId), {
                location: locationValue
            }).then(r => r)
        }
    })

    return (
        <MainPage>
            <MainContent>
                <PageTitle>{text.locationSetting}</PageTitle>
                <FlexBox>
                    <img src={isDarkMode ? DarkLocationIcon : LocationIcon} alt="location box" width={"50px"}/>
                    <Input defaultValue={locationName} as={ReactGoogleAutocomplete} apiKey={"AIzaSyCcX67loAS8ClbEOiTpfn1Zb2ayiBTHftQ"} placeholder={text.inputLocation} onPlaceSelected={(place :object) => onPlaceChange(place)}></Input>
                </FlexBox>
            </MainContent>
            <SideContent/>
        </MainPage>
    )
}

export default LocationSetting