import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {collection, doc, getFirestore, onSnapshot} from "firebase/firestore";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getDatabase, onValue, ref} from "firebase/database";
import ProfileModal from "../modals/profile";
import ChatSetting from "../modals/chatSetting";
import InviteMember from "../modals/inviteMember";

const SettingIcon = require("../../images/icons/setting.png")
const DarkSettingIcon = require("../../images/icons/dark/setting.png")

const SideContentLayer = styled.div `
    width: 380px;
  
    @media screen and (max-width: 1720px) {
        display: none;
    }
  
    @media screen and (max-height: 750px) {
        display: none;
    }
`

const FixedBox = styled.div`
    position: fixed;
    overflow-y: auto;
    height: 100%;
    width: 405px;
`

const TagsLayer = styled.div`
    width: 380px;
    margin: 110px auto 0;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    background-color: ${props => props.theme.backgroundColor};
    
    h1 {
        margin: 0 0 0 18px;
        color: ${props => props.theme.color}
    }
`

const OnlineLayer = styled(TagsLayer)`
    margin: 30px auto 0;
`

const SettingLayer = styled(OnlineLayer)`
    display: flex;
    align-items: center;
    cursor: pointer;
  
    h1 {
        margin: 0;
    }
  
    img {
        margin: 0 10px 0 15px;
    }
`

const ProfileLayer = styled.div`
    display: flex;
    align-content: center;
    margin-top: 10px;
`

const ProfileNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
    color: ${props => props.theme.color}
`

const ProfileId = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 5px;
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(59px, -15px);
`

const InviteButton = styled.a<{themeColor :string, themeFontColor :string}>`
    background-color: ${props => props.themeColor};
    color: ${props => props.themeFontColor};
    font-size: 20px;
    padding: 5px 120px;
    border-radius: 10px;
`

const InviteLayer = styled.div`
    text-align: center;
    margin: 25px 0 15px;
`

interface onlineUserProp {
    userObj :any
}

interface ChatSideContentProps {
    chatObj :any,
    themeColor :string,
    themeFontColor :string
}

interface MemberProfileProps {
    userId :string
}

function OnlineUser({userObj} :onlineUserProp) {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [profile, setProfile] = useState<any>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getProfile = onSnapshot(doc(getFirestore(), "users", userObj.userId), (doc :any) => {
            setProfile(doc.data())
        })

        return(() => {
            getStatus()
            getProfile()
        })
    }, [userObj])

    function onProfileClick() {
        navigate(`/profile/${profile.id}`, {state: {userObj, profile}})
    }

    if (isOnline && profile !== null) {
        return (
            <ProfileLayer>
                <a onClick={onProfileClick}>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                </a>
                <div style={{margin: "11px", marginBottom: "0", display: "block"}}>
                    <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                    <ProfileId>
                        <p>{"@" + profile.id}</p>
                    </ProfileId>
                </div>
            </ProfileLayer>
        )
    } else {
        return null
    }
}

function MemberProfile({userId} :MemberProfileProps) {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [profile, setProfile] = useState<any>(null)
    const [showProfile, setShowProfile] = useState<boolean>(false)

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getProfile = onSnapshot(doc(getFirestore(), "users", userId), (doc :any) => {
            setProfile(doc.data())
        })

        return(() => {
            getStatus()
            getProfile()
        })
    }, [userId])

    if (profile !== null) {
        return (
            <ProfileLayer>
                <ProfileModal profile={profile} setShowProfile={setShowProfile} showProfile={showProfile}/>
                <a onClick={() => setShowProfile(true)}>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                </a>
                <div style={{margin: "11px", marginBottom: "0", display: "block"}}>
                    <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                    <ProfileId>
                        <p>{"@" + profile.id}</p>
                    </ProfileId>
                </div>
            </ProfileLayer>
        )
    } else {
        return null
    }
}

function ChatSideContent({chatObj, themeColor, themeFontColor} :ChatSideContentProps) {
    const [following, setFollowing] = useState<object[]>([])
    const [showChatSetting, setShowChatSetting] = useState<boolean>(false)
    const [showInviteMember, setShowInviteMember] = useState<boolean>(false)

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, text, isDarkMode] = [data.userObj, data.text, data.isDarkMode]

    useEffect(() => {
        const getFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            let followingArray :object[] = []
            querySnapshot.forEach((doc :any) => {
                if (doc.exists) {
                    followingArray.push(doc.data())
                }
            })
            setFollowing(followingArray)
        })

        return(() => getFollowing())
    }, [userObj])

    return (
        <SideContentLayer>
            <ChatSetting showChatSetting={showChatSetting} setShowChatSetting={setShowChatSetting} chatObj={chatObj}/>
            <InviteMember chatObj={chatObj} setShowInviteMember={setShowInviteMember} showInviteMember={showInviteMember}/>
            <FixedBox>
                <TagsLayer>
                    <h1>Members</h1>
                    {chatObj.member.map((e :string, i :number) => (
                        <MemberProfile key={i} userId={e}/>
                    ))}
                    {chatObj.chatType === "group" ? (
                        <InviteLayer>
                            <InviteButton onClick={() => setShowInviteMember(true)} themeColor={themeColor} themeFontColor={themeFontColor}>Invite</InviteButton>
                        </InviteLayer>
                    ) : null}
                </TagsLayer>
                <OnlineLayer>
                    <h1>{text.onlineUsers}</h1>
                    {following.map((e :any, i :number) => (
                        <OnlineUser key={i} userObj={e}/>
                    ))}
                </OnlineLayer>
                <SettingLayer onClick={() => setShowChatSetting(true)}>
                    <img src={isDarkMode ? DarkSettingIcon : SettingIcon} alt="setting icon" width={"50px"}/>
                    <h1>Settings</h1>
                </SettingLayer>
            </FixedBox>
        </SideContentLayer>
    )
}

export default ChatSideContent