import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import {getDatabase, onValue, ref} from "firebase/database";
import {useParams} from "react-router-dom";
import {format} from "timeago.js";

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 800px;
    max-height: 800px;
    border-radius: 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }

    @media screen and (max-height: 850px) {
        width: 100%;
        bottom: 0;
        max-height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const ModalTitle = styled.h1`
    margin: 50px 0 30px;
    font-size: 40px;
    text-align: center;
`

const FlexBox = styled.div`
    display: flex;
    margin: 30px 150px;
    justify-content: space-between;
  
    @media screen and (max-width: 620px) {
        margin: 30px 20px;
    }
`

const ProfileLayer = styled.div`
    display: flex;
    color: ${props => props.theme.color};
    text-align: left;
  
    div {
        margin: auto 5px;
    }
  
    h3 {
        margin: 0;
        font-size: 20px;
    }
  
    p {
        margin: 0;
        font-size: 12px;
        color: #7c7c7c;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 13px;
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(35px, 35px);
`

const Margin = styled.div`
    height: 20px;
`

const LastCheckTime = styled.p`
    text-align: right;
    
    @media screen and (max-width: 620px) {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

interface UncheckedMemberProps {
    showUncheckedMember :boolean,
    setShowUncheckedMember :(active :boolean) => void,
    uncheckedMember :string[],
}

interface MemberProfileProps {
    userId :string
}

function MemberProfile({userId} :MemberProfileProps) {
    const [profile, setProfile] = useState<any>()
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [lastCheck, setLastCheck] = useState<string>("")
    const {chatId} = useParams()

    useEffect(() => {
        const getProfile = onSnapshot(doc(getFirestore(), "users", userId), (doc) => {
            if (doc.exists()) {
                setProfile(doc.data())
            }
        })

        const getStatus = onValue(ref(getDatabase(), `status/${userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getLastCheck = onSnapshot(doc(getFirestore(), `chats/${chatId}/lastCheck`, userId), (doc) => {
            if (doc.exists()) {
                setLastCheck(format(doc.data().lastCheckTime.seconds * 1000))
            }
        })

        return(() => {
            getProfile()
            getStatus()
            getLastCheck()
        })
    }, [userId])

    if (profile !== undefined) {
        return (
            <FlexBox>
                <ProfileLayer>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight/>
                    ) : null}
                    <div>
                        <h3>{profile.name}</h3>
                        <p>{"@" + profile.id}</p>
                    </div>
                </ProfileLayer>
                <LastCheckTime>{lastCheck !== "" ? "Checked " + lastCheck : "Unchecked"}</LastCheckTime>
            </FlexBox>
        )
    } else {
        return null
    }
}

function UncheckedMember({showUncheckedMember, setShowUncheckedMember, uncheckedMember} :UncheckedMemberProps) {
    if (showUncheckedMember) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={() => setShowUncheckedMember(false)}/>
                <ModalLayer>
                    <ModalTitle>Unchecked members</ModalTitle>
                    {uncheckedMember.map((e, i) => (
                        <MemberProfile userId={e} key={i}/>
                    ))}
                    <Margin/>
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default UncheckedMember