import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getDatabase, onValue, ref} from "firebase/database";
import {collection, getFirestore, onSnapshot, query, where} from "firebase/firestore";
import {useNavigate} from "react-router-dom";

const HomeIcon = require("../../../images/icons/dark/dark-home.png")
const DarkLogo = require("../../../images/chat-logo-white.png")
const NotificationIcon = require("../../../images/icons/dark/notification.png")
const SearchIcon = require("../../../images/icons/dark/search.png")
const LandIcon = require("../../../images/icons/dark/tunip-logo-white.png")
const SettingIcon = require("../../../images/icons/dark/setting.png")
const FollowingIcon = require("../../../images/icons/dark/follow.png")

const NavbarBox = styled.div`
    width: 100%;
    height: 60px;
    background-color: black;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NavbarItem = styled.a`
    margin: 0 auto;
    display: flex;
    position: absolute;
  
    img {
       border-radius: 90px;
    }
`

const OnlineLight = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(15px, 23px);
    margin: 2px 2px 2px 10px;
`

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 10001;
`

const NavMenu = styled.div`
    width: 280px;
    height: 100%;
    background-color: black;
    position: fixed;
    z-index: 10001;
    left: 0;
    bottom: 0;
    box-shadow: 10px 0 20px #00000070;
`

const AccountLayer = styled.div`
    margin: 15px;
    display: flex;
    color: white;
    align-content: center;
    
    div {
        margin-left: 10px;
        margin-top: 7px;
    }
  
    h6, p {
        margin: 0;
    }
  
    h6 {
        font-weight: lighter;
        color: #7c7c7c;
    }
`

const NavItemLayer = styled.div`
    margin: 30px 15px 15px;
`

const NavItem = styled.a`
    margin-top: 15px;
    margin-left: 7px;
    display: flex;
    align-content: center;
    text-decoration: none;

    h1 {
        color: white;
        margin: auto 10px;
        font-size: 20px;
        font-weight: lighter;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 35px;
    height: 35px;
    border-radius: 90px;
`

const UserProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 35px;
    height: 35px;
    border-radius: 90px;
    margin: 0;
`

const UncheckedNotificationLight = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 90px;
    background-color: #FF4A4A;
    position: absolute;
    transform: translate(25px, 23px);
`

interface MobileNavbarOpenedProp {
    showNavMenu :boolean,
    setShowNavMenu :(active :boolean) => void
}

function MobileNavbarOpened({showNavMenu, setShowNavMenu} :MobileNavbarOpenedProp) {
    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    function onOverlayClick() {
        setShowNavMenu(false)
    }

    if (showNavMenu) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={onOverlayClick}/>
                <NavMenu>
                    <AccountLayer>
                        <UserProfileImage image={userObj.profilePhoto}/>
                        <div>
                            <p>{userObj.name}</p>
                            <h6>{"@" + userObj.id}</h6>
                        </div>
                    </AccountLayer>
                    <NavItemLayer>
                        <NavItem onClick={() => navigate("/")}>
                            <img src={HomeIcon} alt="home icon" width={"35px"}/>
                            <h1>Home</h1>
                        </NavItem>
                        <NavItem onClick={() => navigate("/notification")}>
                            <img src={NotificationIcon} alt="notification icon" width={"35px"}/>
                            <h1>Notifications</h1>
                        </NavItem>
                        <NavItem onClick={() => navigate("/explore")}>
                            <img src={SearchIcon} alt="search icon" width={"35px"}/>
                            <h1>Explore</h1>
                        </NavItem>
                        <NavItem onClick={() => navigate("/following")}>
                            <img src={FollowingIcon} alt="following icon" width={"35px"}/>
                            <h1>Following</h1>
                        </NavItem>
                        <NavItem href={"https://tunip.land/"}>
                            <img src={LandIcon} alt="land icon" width={"35px"}/>
                            <h1>Land</h1>
                        </NavItem>
                        <NavItem onClick={() => navigate("/setting")}>
                            <img src={SettingIcon} alt="setting icon" width={"35px"}/>
                            <h1>Setting</h1>
                        </NavItem>
                    </NavItemLayer>
                </NavMenu>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

function MobileNavbar() {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [showNavMenu, setShowNavMenu] = useState<boolean>(false)
    const [showUncheckedNotificationLight, setShowUncheckedNotificationLight] = useState<boolean>(false)

    const data = useSelector((state => state))
    // @ts-ignore
    const [userObj] = [data.userObj]
    const navigate = useNavigate()

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userObj.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val()) {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getNotification = onSnapshot(query(collection(getFirestore(), `users/${userObj.userId}/notifications`), where("isChecked", "==", false)), (snapshot) => {
            let notificationArray = []
            snapshot.forEach((doc) => {
                if (doc.exists()) {
                    notificationArray.push(doc.data())
                }
            })

            if (notificationArray.length === 0) {
                setShowUncheckedNotificationLight(false)
            } else {
                setShowUncheckedNotificationLight(true)
            }
        })

        return (() => {
            getStatus()
            getNotification()
        })
    }, [userObj])

    function onProfileClick() {
        setShowNavMenu(true)
    }

    function onNotificationClick() {
        navigate("/notification", {state: {userObj}})
    }

    return (
        <div>
            <MobileNavbarOpened showNavMenu={showNavMenu} setShowNavMenu={setShowNavMenu}/>
            <NavbarBox>
                <NavbarItem style={{left: "15px"}} onClick={onProfileClick}>
                    <ProfileImage image={userObj.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                </NavbarItem>
                <NavbarItem>
                    <img src={DarkLogo} alt="tunip logo" width={"35px"}/>
                </NavbarItem>
                <NavbarItem style={{right: "15px"}} onClick={onNotificationClick}>
                    <img src={NotificationIcon} alt="notification icon" width={"35px"}/>
                    {showUncheckedNotificationLight ? (
                        <UncheckedNotificationLight/>
                    ) : null}
                </NavbarItem>
            </NavbarBox>
        </div>
    )
}

export default MobileNavbar