import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import EN from "./languages/en.json"

import { createStore } from "redux";
import { Provider } from "react-redux";

function reducer(currentState :any, action :any) {
    if (currentState === undefined) {
        return {
            userObj: {},
            isLoggedIn: true,
            accountSetupComplete: true,
            token: "",
            isDarkMode: false,
            text: {...EN}
        }
    }

    const newState = {...currentState}

    if (action.type === "SET userObj") {
        newState.userObj = action.input
    } else if (action.type === "SET isLoggedIn") {
        newState.isLoggedIn = action.input
    } else if (action.type === "SET accountSetupComplete") {
        newState.accountSetupComplete = action.input
    } else if (action.type === "SET token") {
        newState.token = action.input
    } else if (action.type === "SET isDarkMode") {
        newState.isDarkMode = action.input
    } else if (action.type === "SET text") {
        newState.text = action.input
    }

    return newState
}

const store = createStore(reducer)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
