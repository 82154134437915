import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getDatabase, onValue, ref} from "firebase/database";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import ProfileModal from "../profile";
import {useSelector} from "react-redux";
import ChatSetting from "../chatSetting";
import InviteMember from "../inviteMember";

const SettingIcon = require("../../../images/icons/setting.png")
const DarkSettingIcon = require("../../../images/icons/dark/setting.png")

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 310px;
    height: 100%;
    border-radius: 30px 0 0 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};
    right: 0;
    bottom: 0;
`

const MemberLayer = styled.div`
    margin: 25px;
  
    h1 {
        margin-bottom: 0;
        font-size: 30px;
    }
`

const ProfileLayer = styled.div`
    display: flex;
    align-content: center;
    margin-top: 10px;
`

const ProfileNameLayer = styled.p`
    font-size: 20px;
    margin: 0;
    color: ${props => props.theme.color}
`

const ProfileId = styled.div`
    margin: 0;
    display: flex;
    
    p {
        font-size: 10px;
        color: #7C7C7C;
        margin: 0 10px 0 0;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-top: 5px;
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(35px, -15px);
`

const InviteButton = styled.a`
    background-color: #00d2ff;
    font-size: 20px;
    padding: 5px 100px;
    border-radius: 10px;
    color: black;
`

const InviteLayer = styled.div`
    text-align: center;
    margin: 25px 0 15px;
`

const SettingLayer = styled(MemberLayer)`
    display: flex;
    align-items: center;
    margin-top: 40px;
    cursor: pointer;
    
    h1 {
        margin: 0;
    }
  
    img {
        margin: 0 10px 0 0;
    }
`

interface ChatMenuProps {
    showChatMenu :boolean,
    setShowChatMenu :(active :boolean) => void,
    chatObj :any
}

interface MemberProfileProps {
    userId :string
}

function MemberProfile({userId} :MemberProfileProps) {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [profile, setProfile] = useState<any>(null)
    const [showProfile, setShowProfile] = useState<boolean>(false)

    useEffect(() => {
        const getStatus = onValue(ref(getDatabase(), `status/${userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        const getProfile = onSnapshot(doc(getFirestore(), "users", userId), (doc :any) => {
            setProfile(doc.data())
        })

        return(() => {
            getStatus()
            getProfile()
        })
    }, [userId])

    if (profile !== null) {
        return (
            <ProfileLayer>
                <ProfileModal profile={profile} setShowProfile={setShowProfile} showProfile={showProfile}/>
                <a onClick={() => setShowProfile(true)}>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight></OnlineLight>
                    ) : null}
                </a>
                <div style={{margin: "11px", marginBottom: "0", display: "block"}}>
                    <ProfileNameLayer>{profile.name}</ProfileNameLayer>
                    <ProfileId>
                        <p>{"@" + profile.id}</p>
                    </ProfileId>
                </div>
            </ProfileLayer>
        )
    } else {
        return null
    }
}

function ChatMenu({showChatMenu, setShowChatMenu, chatObj} :ChatMenuProps) {
    const [showChatSetting, setShowChatSetting] = useState<boolean>(false)
    const [showInviteMember, setShowInviteMember] = useState<boolean>(false)
    const data = useSelector((state => state))
    // @ts-ignore
    const [isDarkMode] = [data.isDarkMode]

    if (showChatMenu) {
        return (
            <ModalOverBox>
                <ChatSetting showChatSetting={showChatSetting} setShowChatSetting={setShowChatSetting} chatObj={chatObj}/>
                <InviteMember chatObj={chatObj} setShowInviteMember={setShowInviteMember} showInviteMember={showInviteMember}/>
                <ModalOverlay onClick={() => setShowChatMenu(false)}/>
                <ModalLayer>
                    <MemberLayer>
                        <h1>Members</h1>
                        {chatObj.member.map((e :string, i :number) => (
                            <MemberProfile key={i} userId={e}/>
                        ))}
                        {chatObj.chatType === "group" ? (
                            <InviteLayer>
                                <InviteButton onClick={() => setShowInviteMember(true)}>Invite</InviteButton>
                            </InviteLayer>
                        ) : null}
                    </MemberLayer>
                    <SettingLayer onClick={() => setShowChatSetting(true)}>
                        <img src={isDarkMode ? DarkSettingIcon : SettingIcon} alt="setting icon" width={"50px"}/>
                        <h1>Settings</h1>
                    </SettingLayer>
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default ChatMenu