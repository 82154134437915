import React from "react";
import styled from "styled-components";
import MainSideContent from "./modules/mainSideContent";
import {signOut, getAuth} from "firebase/auth"
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {arrayRemove, doc, getFirestore, updateDoc} from "firebase/firestore";
import {getDatabase, ref, serverTimestamp, set} from "firebase/database";

const AccountSettingIcon = require("../images/icons/set-account.png")
const LanguageSettingIcon = require("../images/icons/set-language.png")
const LocationSettingIcon = require("../images/icons/set-location.png")
const NotificationSettingIcon = require("../images/icons/set-notification.png")
const DarkModeSettingIcon = require("../images/icons/set-darkmode.png")
const DarkDarkModeSettingIcon = require("../images/icons/dark/dark-set-darkmode.png")

const MainPage = styled.div `
    display: flex;
`

const MainContent = styled.div `
    width: 850px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 125px;
  
    @media screen and (max-height: 750px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 850px) {
        width: 650px;
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 620px) {
        width: 360px;
        margin-top: 80px
    }
`

const PageTitle = styled.h1`
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const SettingLayer = styled.div`
    width: 90%;
    margin: 0 auto;
`

const PartTitle = styled.p`
    margin-left: 20px;
    margin-bottom: 0;
    font-size: 20px;
    color: ${props => props.theme.color};

    @media screen and (max-width: 620px) {
        margin-left: 10px;
    }
`

const SettingButton = styled.a`
    display: flex;
    margin: 15px;
    align-items: center;
    color: black;
    text-decoration: none;
  
    p {
        margin: 20px;
        font-size: 25px;
        color: ${props => props.theme.color}
    }

    @media screen and (max-width: 620px) {
        margin: 5px;
        
        p {
            font-size: 15px;
        }
    }
`

const MarginLayer = styled.div`
    height: 20px;
`

const SignOutButton = styled.a`
    margin: 0 auto;
    background-color: #FF4A4A;
    color: white;
    padding: 15px 80px;
    border-radius: 90px;
    font-size: 20px;
`

const SignOutLayer = styled.div`
    text-align: center;
    margin: 50px 0;
`

function Setting() {
    const navigate = useNavigate()
    const data = useSelector((state => state))
    // @ts-ignore
    const [token, userObj, text, isDarkMode] = [data.token, data.userObj, data.text, data.isDarkMode]

    function rtdb_and_local_fs_presence() {
        const uid = userObj.userId;
        const userStatusFirestoreRef = ref(getDatabase(), "/status/" + uid);
        const isOfflineForFirestore = {
            state: 'offline',
            last_changed: serverTimestamp(),
        };

        set(userStatusFirestoreRef, isOfflineForFirestore).then(r => r)
    }

    async function onSignOutClick() {
        if (userObj.userId !== undefined) {
            if (window.location.protocol === "https:" || window.location.hostname === "localhost") {
                if (token !== "") {
                    await updateDoc(doc(getFirestore(), "users", userObj.userId), {
                        chatToken: arrayRemove(token)
                    }).then(r => r)
                }
            }
            rtdb_and_local_fs_presence()
            signOut(getAuth()).then(r => r)
            window.location.href = window.location.origin
        }
    }

    return (
        <MainPage>
            <MainContent>
                <PageTitle>{text.settings}</PageTitle>
                <SettingLayer>
                    <PartTitle>{text.general}</PartTitle>
                    <SettingButton onClick={() => navigate("/setting/profile")}>
                        <img width={"60px"} src={AccountSettingIcon} alt="account setting icon"/>
                        <p>{text.profileSetting}</p>
                    </SettingButton>
                    <SettingButton onClick={() => navigate("/setting/language")}>
                        <img width={"60px"} src={LanguageSettingIcon} alt="Language setting icon"/>
                        <p>{text.languageSetting}</p>
                    </SettingButton>
                    <SettingButton onClick={() => navigate("/setting/location")}>
                        <img width={"60px"} src={LocationSettingIcon} alt="Location setting icon"/>
                        <p>{text.locationSetting}</p>
                    </SettingButton>
                    <SettingButton onClick={() => navigate("/setting/notification")}>
                        <img width={"60px"} src={NotificationSettingIcon} alt="Notification setting icon"/>
                        <p>{text.notificationSetting}</p>
                    </SettingButton>
                    <MarginLayer/>
                    <PartTitle>{text.theme}</PartTitle>
                    <SettingButton onClick={() => navigate("/setting/darkmode")}>
                        <img width={"60px"} src={isDarkMode ? DarkDarkModeSettingIcon : DarkModeSettingIcon} alt="Dark mode setting icon"/>
                        <p>{text.darkModeSetting}</p>
                    </SettingButton>
                    <MarginLayer/>
                    <SignOutLayer>
                        <SignOutButton onClick={onSignOutClick}>{text.signOut}</SignOutButton>
                    </SignOutLayer>
                    <MarginLayer/>
                    <MarginLayer/>
                </SettingLayer>
            </MainContent>
            <MainSideContent/>
        </MainPage>
    )
}

export default Setting