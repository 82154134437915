import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {collection, doc, getFirestore, onSnapshot} from "firebase/firestore";
import ProfileModal from "../modals/profile";
import ImageViewer from "../modals/imageViewer";
import UncheckedMember from "../modals/uncheckedMember";
import MessageMenu from "../modals/messageMenu";

const LinkImage = require("../../images/link.png")

const MyMessageLayer = styled.div<{isLastMessage :boolean, isFirstMessage :boolean}>`
    display: flex;
    justify-content: right;
    margin-top: ${props => props.isFirstMessage ? "10px" : "5px"};
    margin-bottom: ${props => props.isLastMessage ? "10px" : "5px"};

    @media screen and (max-width: 620px) {
        margin-top: ${props => props.isFirstMessage ? "10px" : "4px"};
        margin-bottom: ${props => props.isLastMessage ? "10px" : "4px"};
    }
`

const MessageLayer = styled.div<{isLastMessage :boolean, isFirstMessage :boolean}>`
    display: flex;
    justify-content: left;
    text-align: left;
    margin-left: 5px;
    margin-top: ${props => props.isFirstMessage ? "10px" : "0"};
    margin-bottom: ${props => props.isLastMessage ? "10px" : "0"};
  
    @media screen and (max-width: 620px) {
        margin-top: ${props => props.isFirstMessage ? "5px" : "0"};
        margin-bottom: ${props => props.isLastMessage ? "5px" : "0"};
    }
`

const MyMessageTextContent = styled.div<{themeColor :string, themeFontColor :string, isLastMessage :boolean}>`
    padding: 8px 15px;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: ${props => props.isLastMessage ? `22px 10px 22px 22px` : `22px 10px 10px 22px`};
    background-color: ${props => props.themeColor};
    margin-right: 7px;
    max-width: 360px;
    word-break: break-all;
    text-align: left;
    cursor: pointer;
  
    p {
        margin: 0;
        font-size: 20px;
        color: ${props => props.themeFontColor}
    }

    @media screen and (max-width: 1340px) {
        max-width: 330px;
    }

    @media screen and (max-width: 620px) {
        padding: 6px 13px;
        border-radius: ${props => props.isLastMessage ? `18px 8px 18px 18px` : `18px 8px 8px 18px`};
    
        p {
            font-size: 17px;
        }
    }
`

const MessageTextContent = styled.div<{themeFontColor :string, isFirstMessage :boolean, isLastMessage :boolean}>`
    padding: 8px 15px;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: ${props => props.isLastMessage ? `10px 22px 22px 22px` : `10px 22px 22px 10px`};
    background-color: ${props => props.theme.backgroundColor};
    margin-left: ${props => props.isFirstMessage ? "5px" : "55px"};
    margin-top: ${props => props.isFirstMessage ? "25px" : "5px"};
    margin-right: 5px;
    max-width: 360px;
    word-break: break-all;
    text-align: left;
    cursor: pointer;
  
    p {
        margin: 0;
        font-size: 20px;
    }
  
    @media screen and (max-width: 1340px) {
        max-width: 330px;
    }
  
    @media screen and (max-width: 620px) {
        padding: 6px 13px 6px 11.5px;
        margin-left: ${props => props.isFirstMessage ? "5px" : "45px"};
        margin-top: ${props => props.isFirstMessage ? "21px" : "4px"};
        max-width: 200px;
        border-radius: ${props => props.isLastMessage ? `8px 18px 18px 18px` : `8px 18px 18px 8px`};
      
        p {
            font-size: 17px;
        }
    }
`

const MyMessageUrlContent = styled(MyMessageTextContent)`
    color: #0064FF;
    text-decoration: underline;
`

const MessageUrlContent = styled(MessageTextContent)`
    text-decoration: underline;
    color: #0064FF;
`

const MyMessageImageContent = styled.img<{isLastMessage :boolean}>`
    width: 400px;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: ${props => props.isLastMessage ? `22px 10px 22px 22px` : `22px 10px 10px 22px`};
    margin-right: 7px;

    @media screen and (max-width: 1340px) {
        max-width: 350px;
    }
    
    @media screen and (max-width: 620px) {
        width: 240px;
        border-radius: ${props => props.isLastMessage ? `18px 8px 18px 18px` : `18px 8px 8px 18px`};
    }
`

const MessageImageContent = styled.img<{isFirstMessage :boolean, isLastMessage :boolean}>`
    width: 400px;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: ${props => props.isLastMessage ? `10px 22px 22px 22px` : `10px 22px 22px 10px`};
    margin-left: ${props => props.isFirstMessage ? "5px" : "55px"};
    margin-top: ${props => props.isFirstMessage ? "25px" : "5px"};
    margin-right: 5px;

    @media screen and (max-width: 1340px) {
        width: 370px;
    }
  
    @media screen and (max-width: 620px) {
        width: 240px;
        margin-top: ${props => props.isFirstMessage ? "21px" : "5px"};
        border-radius: ${props => props.isLastMessage ? `8px 18px 18px 18px` : `8px 18px 18px 8px`};
        margin-bottom: ${props => props.isLastMessage ? `0` : `-4px`};
        margin-left: ${props => props.isFirstMessage ? "5px" : "45px"};
    }
`

const MyMessageUrlPreview = styled(MyMessageImageContent)`
    background-color: ${props => props.theme.backgroundColor};
  
    img {
        box-shadow: none;
    }
  
    p {
        margin: 5px 15px 10px 15px;
        text-align: left;
        color: ${props => props.theme.color};
        text-decoration: none;
    }
`

const MessageUrlPreview = styled(MessageImageContent)`
    background-color: ${props => props.theme.backgroundColor};
  
    img {
        box-shadow: none;
        margin-top: 0;
        margin-left: 0;
    }
    
    p {
        margin: 5px 15px 10px 15px;
        text-align: left;
        color: ${props => props.theme.color};
        text-decoration: none;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;

    @media screen and (max-width: 620px) {
        width: 40px;
        height: 40px;
        margin-right: 0;
    }
`

const AuthorName = styled.p`
    margin: 0 5px;
    position: absolute;
    min-width: 100px;
    z-index: -1000;
  
    @media screen and (max-width: 620px) {
        font-size: 15px;
    }
`

const DateChangeMessageLayer = styled.div`
    margin: 20px;
  
    p {
        font-size: 15px;
        margin: 0;
    }
  
    @media screen and (max-width: 620px) {
        margin: 15px;
      
        p {
            font-size: 10px;
        }
    }
`

const MessageSendTimeLayer = styled.div`
    margin: 0 5px 0 0;
    position: relative;
    width: 60px;
`

const MessageSendTime = styled.p<{isMyMessage :boolean}>`
    margin: 0;
    font-size: 12px;
    position: absolute;
    z-index: -1000;
    bottom: 0;
    ${props => props.isMyMessage ? `right: 0` : `left: 0`};

    @media screen and (max-width: 620px) {
        font-size: 10px;
    }
`

const UncheckedCount = styled.a<{themeColor :string, isLastMessage :boolean, isMyMessage :boolean}>`
    color: ${props => props.themeColor};
    margin: ${props => props.isLastMessage ? `0 0 14px 0` : `0`};
    position: absolute;
    ${props => props.isMyMessage ? `right: 0` : `left: 0`};
    bottom: 0;
    font-size: 12px;

    @media screen and (max-width: 620px) {
        font-size: 10px;
    }
`

interface MessageProps {
    messageObj :any,
    themeColor :string,
    themeFontColor :string,
    prevMessage :any,
    nextMessage :any,
    chatObj :any,
    messageId :string
}

function Message({messageObj, themeColor, themeFontColor, nextMessage, prevMessage, chatObj, messageId} :MessageProps) {
    const [isMyMessage, setIsMyMessage] = useState<boolean>(false)
    const [authorProfile, setAuthorProfile] = useState<any>()
    const [isLastMessage, setIsLastMessage] = useState<boolean>(false)
    const [isFirstMessage, setIsFirstMessage] = useState<boolean>(true)
    const [unCheckedUsers, setUncheckedUsers] = useState<string[]>([])
    const [showProfile, setShowProfile] = useState<boolean>(false)
    const [showUncheckedMember, setShowUncheckedMember] = useState<boolean>(false)
    const [showMessageMenu, setShowMessageMenu] = useState<boolean>(false)

    const [showImageViewer, setShowImageViewer] = useState<boolean>(false)
    const [url, setUrl] = useState<string>("")

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        if (messageObj.author === userObj.userId) {
            setIsMyMessage(true)
        } else {
            setIsMyMessage(false)
        }

        if (nextMessage !== undefined && prevMessage !== undefined) {
            if (nextMessage.author === messageObj.author &&
                new Date(nextMessage.sendedAt).getHours() === new Date(messageObj.sendedAt).getHours() &&
                new Date(nextMessage.sendedAt).getMinutes() === new Date(messageObj.sendedAt).getMinutes()) {
                    setIsLastMessage(false)
            } else {
                setIsLastMessage(true)
            }

            if (prevMessage.author === messageObj.author &&
                new Date(prevMessage.sendedAt).getHours() === new Date(messageObj.sendedAt).getHours() &&
                new Date(prevMessage.sendedAt).getMinutes() === new Date(messageObj.sendedAt).getMinutes()) {
                setIsFirstMessage(false)
            } else {
                setIsFirstMessage(true)
            }
        } else {
            if (nextMessage === undefined && prevMessage !== undefined) {
                setIsLastMessage(true)
                if (prevMessage.author === messageObj.author &&
                    new Date(prevMessage.sendedAt).getHours() === new Date(messageObj.sendedAt).getHours() &&
                    new Date(prevMessage.sendedAt).getMinutes() === new Date(messageObj.sendedAt).getMinutes()) {
                    setIsFirstMessage(false)
                } else {
                    setIsFirstMessage(true)
                }
            } else if (nextMessage !== undefined && prevMessage === undefined) {
                setIsFirstMessage(true)
                if (nextMessage.author === messageObj.author &&
                    new Date(nextMessage.sendedAt).getHours() === new Date(messageObj.sendedAt).getHours() &&
                    new Date(nextMessage.sendedAt).getMinutes() === new Date(messageObj.sendedAt).getMinutes()) {
                    setIsLastMessage(false)
                } else {
                    setIsLastMessage(true)
                }
            } else {
                setIsLastMessage(true)
                setIsFirstMessage(true)
            }
        }

        const getUncheckedUser = onSnapshot(collection(getFirestore(), `chats/${chatObj.id}/lastCheck`), (querySnapshot) => {
            const uncheckedArray :string[] = []
            const checkedMemberArray :string[] = []

            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    if (doc.data().lastCheckTime.seconds * 1000 <= messageObj.sendedAt) {
                        uncheckedArray.push(doc.id)
                    }
                    checkedMemberArray.push(doc.id)
                }
            })

            chatObj.member.forEach((e :string) => {
                if (checkedMemberArray.indexOf(e) === -1) {
                    uncheckedArray.push(e)
                }
            })

            setUncheckedUsers(uncheckedArray)
        })

        if (messageObj.type === "url") {
            if (messageObj.content.search(":") > -1 && messageObj.content.search(":") < 7) {
                setUrl(messageObj.content)
            } else {
                setUrl("http://" + messageObj.content)
            }
        }

        return(() => getUncheckedUser())
    }, [messageObj])

    useEffect(() => {
        if (!isMyMessage) {
            const getProfile = onSnapshot(doc(getFirestore(), "users", messageObj.author), (doc) => {
                if (doc.exists()) {
                    setAuthorProfile(doc.data())
                }
            })

            return(() => getProfile())
        }
    }, [isMyMessage, messageObj])

    if (messageObj.type === "text") {
        if (isMyMessage) {
            return (
                <MyMessageLayer isLastMessage={isLastMessage} isFirstMessage={isFirstMessage}>
                    <UncheckedMember setShowUncheckedMember={setShowUncheckedMember} showUncheckedMember={showUncheckedMember} uncheckedMember={unCheckedUsers}/>
                    <MessageMenu showMessageMenu={showMessageMenu} setShowMessageMenu={setShowMessageMenu} messageObj={messageObj} chatObj={chatObj} messageId={messageId} isMyMessage={isMyMessage}/>
                    <MessageSendTimeLayer>
                        {unCheckedUsers.length !== 0 ? (
                            <UncheckedCount themeColor={themeColor} isLastMessage={isLastMessage} isMyMessage={isMyMessage} onClick={() => setShowUncheckedMember(true)}>{unCheckedUsers.length}</UncheckedCount>
                        ) : null}
                        {isLastMessage ? (
                            <MessageSendTime isMyMessage={isMyMessage}>
                                {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(new Date(messageObj.sendedAt))}
                            </MessageSendTime>
                        ) : null}
                    </MessageSendTimeLayer>
                    <MyMessageTextContent themeColor={themeColor} themeFontColor={themeFontColor} isLastMessage={isLastMessage} onClick={() => setShowMessageMenu(true)}>
                        <p>{messageObj.content}</p>
                    </MyMessageTextContent>
                </MyMessageLayer>
            )
        } else {
            if (authorProfile !== undefined) {
                return (
                    <MessageLayer isLastMessage={isLastMessage} isFirstMessage={isFirstMessage}>
                        <ProfileModal showProfile={showProfile} setShowProfile={setShowProfile} profile={authorProfile}/>
                        <UncheckedMember setShowUncheckedMember={setShowUncheckedMember} showUncheckedMember={showUncheckedMember} uncheckedMember={unCheckedUsers}/>
                        <MessageMenu showMessageMenu={showMessageMenu} setShowMessageMenu={setShowMessageMenu} messageObj={messageObj} chatObj={chatObj} messageId={messageId} isMyMessage={isMyMessage}/>
                        {isFirstMessage ? (
                            <ProfileImage image={authorProfile.profilePhoto} onClick={() => setShowProfile(true)}/>
                        ) : null}
                        <div>
                            {isFirstMessage ? (
                                <AuthorName>{authorProfile.name}</AuthorName>
                            ) : null}
                            <MessageTextContent themeFontColor={themeFontColor} isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} onClick={() => setShowMessageMenu(true)}>
                                <p>{messageObj.content}</p>
                            </MessageTextContent>
                        </div>
                        <MessageSendTimeLayer>
                            {unCheckedUsers.length !== 0 ? (
                                <UncheckedCount themeColor={themeColor} isLastMessage={isLastMessage} isMyMessage={isMyMessage} onClick={() => setShowUncheckedMember(true)}>{unCheckedUsers.length}</UncheckedCount>
                            ) : null}
                            {isLastMessage ? (
                                <MessageSendTime isMyMessage={isMyMessage}>
                                    {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(new Date(messageObj.sendedAt))}
                                </MessageSendTime>
                            ) : null}
                        </MessageSendTimeLayer>
                    </MessageLayer>
                )
            } else {
                return null
            }
        }
    } else if (messageObj.type === "image") {
        if (isMyMessage) {
            return (
                <MyMessageLayer isLastMessage={isLastMessage} isFirstMessage={isFirstMessage}>
                    <ImageViewer showImageViewer={showImageViewer} setShowImageViewer={setShowImageViewer} imageContent={messageObj.content}/>
                    <UncheckedMember setShowUncheckedMember={setShowUncheckedMember} showUncheckedMember={showUncheckedMember} uncheckedMember={unCheckedUsers}/>
                    <MessageSendTimeLayer>
                        {unCheckedUsers.length !== 0 ? (
                            <UncheckedCount themeColor={themeColor} isLastMessage={isLastMessage} isMyMessage={isMyMessage} onClick={() => setShowUncheckedMember(true)}>{unCheckedUsers.length}</UncheckedCount>
                        ) : null}
                        {isLastMessage ? (
                            <MessageSendTime isMyMessage={isMyMessage}>
                                {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(new Date(messageObj.sendedAt))}
                            </MessageSendTime>
                        ) : null}
                    </MessageSendTimeLayer>
                    <MyMessageImageContent src={messageObj.content} isLastMessage={isLastMessage} onClick={() => setShowImageViewer(true)}/>
                </MyMessageLayer>
            )
        } else {
            if (authorProfile !== undefined) {
                return (
                    <MessageLayer isLastMessage={isLastMessage} isFirstMessage={isFirstMessage}>
                        <ProfileModal showProfile={showProfile} setShowProfile={setShowProfile} profile={authorProfile}/>
                        <ImageViewer showImageViewer={showImageViewer} setShowImageViewer={setShowImageViewer} imageContent={messageObj.content}/>
                        <UncheckedMember setShowUncheckedMember={setShowUncheckedMember} showUncheckedMember={showUncheckedMember} uncheckedMember={unCheckedUsers}/>
                        {isFirstMessage ? (
                            <ProfileImage image={authorProfile.profilePhoto} onClick={() => setShowProfile(true)}/>
                        ) : null}
                        <div>
                            {isFirstMessage ? (
                                <AuthorName>{authorProfile.name}</AuthorName>
                            ) : null}
                            <MessageImageContent isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} src={messageObj.content} onClick={() => setShowImageViewer(true)}/>
                        </div>
                        <MessageSendTimeLayer>
                            {unCheckedUsers.length !== 0 ? (
                                <UncheckedCount themeColor={themeColor} isLastMessage={isLastMessage} isMyMessage={isMyMessage} onClick={() => setShowUncheckedMember(true)}>{unCheckedUsers.length}</UncheckedCount>
                            ) : null}
                            {isLastMessage ? (
                                <MessageSendTime isMyMessage={isMyMessage}>
                                    {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(new Date(messageObj.sendedAt))}
                                </MessageSendTime>
                            ) : null}
                        </MessageSendTimeLayer>
                    </MessageLayer>
                )
            } else {
                return null
            }
        }
    } else if (messageObj.type === "dateChange") {
        return (
            <DateChangeMessageLayer>
                <p>{new Intl.DateTimeFormat("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                    }).format(new Date(messageObj.content))}</p>
            </DateChangeMessageLayer>
        )
    } else if (messageObj.type === "url") {
        if (isMyMessage) {
            return (
                <>
                    <MessageMenu showMessageMenu={showMessageMenu} setShowMessageMenu={setShowMessageMenu} messageObj={messageObj} chatObj={chatObj} messageId={messageId} isMyMessage={isMyMessage}/>
                    <MyMessageLayer isLastMessage={false} isFirstMessage={isFirstMessage}>
                        <MyMessageUrlContent isLastMessage={false} themeColor={themeColor} themeFontColor={themeFontColor} onClick={() => setShowMessageMenu(true)}>
                            {messageObj.content}
                        </MyMessageUrlContent>
                    </MyMessageLayer>
                    <MyMessageLayer isLastMessage={isLastMessage} isFirstMessage={false}>
                        <MessageSendTimeLayer>
                            {unCheckedUsers.length !== 0 ? (
                                <UncheckedCount themeColor={themeColor} isLastMessage={isLastMessage} isMyMessage={isMyMessage}>{unCheckedUsers.length}</UncheckedCount>
                            ) : null}
                            {isLastMessage ? (
                                <MessageSendTime isMyMessage={isMyMessage}>
                                    {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(new Date(messageObj.sendedAt))}
                                </MessageSendTime>
                            ) : null}
                        </MessageSendTimeLayer>
                        <MyMessageUrlPreview as={"a"} href={url} target={"_blank"} isLastMessage={isLastMessage}>
                            <MyMessageImageContent isLastMessage={isLastMessage} src={messageObj.image === "" ? LinkImage : messageObj.image}/>
                            <p>{messageObj.title}</p>
                        </MyMessageUrlPreview>
                    </MyMessageLayer>
                </>
            )
        } else {
            if (authorProfile !== undefined) {
                return (
                    <>
                        <ProfileModal showProfile={showProfile} setShowProfile={setShowProfile} profile={authorProfile}/>
                        <MessageMenu showMessageMenu={showMessageMenu} setShowMessageMenu={setShowMessageMenu} messageObj={messageObj} chatObj={chatObj} messageId={messageId} isMyMessage={isMyMessage}/>
                        <MessageLayer isLastMessage={false} isFirstMessage={isFirstMessage}>
                            {isFirstMessage ? (
                                <ProfileImage image={authorProfile.profilePhoto} onClick={() => setShowProfile(true)}/>
                            ) : null}
                            <div>
                                {isFirstMessage ? (
                                    <AuthorName>{authorProfile.name}</AuthorName>
                                ) : null}
                                <MessageUrlContent isLastMessage={false} isFirstMessage={isFirstMessage} themeFontColor={themeFontColor} onClick={() => setShowMessageMenu(true)}>
                                    <a>{messageObj.content}</a>
                                </MessageUrlContent>
                            </div>
                        </MessageLayer>
                        <MessageLayer isLastMessage={isLastMessage} isFirstMessage={false}>
                            <MessageUrlPreview as={"a"} href={url} target={"_blank"} isFirstMessage={false} isLastMessage={true}>
                                <MessageImageContent isFirstMessage={true} isLastMessage={true} src={messageObj.image === "" ? LinkImage : messageObj.image}/>
                                <p>{messageObj.title}</p>
                            </MessageUrlPreview>
                            <MessageSendTimeLayer>
                                {unCheckedUsers.length !== 0 ? (
                                    <UncheckedCount themeColor={themeColor} isLastMessage={isLastMessage} isMyMessage={isMyMessage}>{unCheckedUsers.length}</UncheckedCount>
                                ) : null}
                                {isLastMessage ? (
                                    <MessageSendTime isMyMessage={isMyMessage}>
                                        {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(new Date(messageObj.sendedAt))}
                                    </MessageSendTime>
                                ) : null}
                            </MessageSendTimeLayer>
                        </MessageLayer>
                    </>
                )
            } else {
                return null
            }
        }
    } else {
        return null
    }
}

export default Message