import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {arrayUnion, collection, doc, getDoc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";
import {getDatabase, onValue, ref} from "firebase/database";

const ModalOverBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11000;
`

const ModalOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 10000;
`

const ModalLayer = styled.div`
    width: 800px;
    max-height: 800px;
    border-radius: 30px;
    background-color: ${props => props.theme.backgroundColor};
    position: fixed;
    box-shadow: ${props => props.theme.boxShadow};
    z-index: 10000;
    overflow-y: auto;
    color: ${props => props.theme.color};

    @media screen and (max-width: 850px) {
        width: 100%;
        bottom: 0;
        height: 80%;
        border-radius: 30px 30px 0 0;
    }

    @media screen and (max-height: 850px) {
        width: 100%;
        bottom: 0;
        height: 80%;
        border-radius: 30px 30px 0 0;
    }
`

const ModalTitle = styled.h1`
    margin: 50px 0 30px;
    font-size: 50px;
    text-align: center;
  
    @media screen and (max-width: 620px) {
        font-size: 40px;
    }
`

const SearchLayer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
`

const UserSearchInput = styled.input`
    background-color: #00000000;
    border: 0;
    font-size: 25px;
    width: 250px;
    border-bottom: 3px solid ${props => props.theme.color};
    text-align: center;
    margin: 0 auto;
    outline: none;
    color: ${props => props.theme.color}
`

const ProfileLayer = styled.div`
    display: flex;
    color: ${props => props.theme.color};
  
    div {
        margin: auto 5px;
    }
  
    h3 {
        margin: 0;
        font-size: 20px;
    }
  
    p {
        margin: 0;
        font-size: 12px;
        color: #7c7c7c;
    }
`

const ProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    margin-left: 24px;
    margin-top: 13px;
`

const FlexBox = styled.div`
    display: flex;
    margin: 30px 150px;
    justify-content: space-between;
  
    @media screen and (max-width: 620px) {
        margin: 30px 30px;
    }
`

const OnlineLight = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 90px;
    background-color: #2FD076;
    position: absolute;
    transform: translate(35px, 35px);
`

const SelectButton = styled.label`
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    box-shadow: ${props => props.theme.boxShadow};
  
    &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 25px;
        height: 25px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }
`

const SettingMenu = styled.div`
    input:checked + label {
        background: white;
      
        &:after {
            background-color: #00d2ff;
        }
    }
`

const MemberPreviewLayer = styled.div`
    display: flex;
    justify-content: center;
`

const ProfilePreviewLayer = styled.div`
    border-radius: 90px;
    box-shadow: 1px 4px 10px #00000028;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    
    p {
        margin: 0;
        padding: 5px 10px 5px 5px;
        font-size: 15px;
    }
`

const PreviewProfileImage = styled.div<{image :string}>`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    margin: 3px;
    width: 29px;
    height: 29px;
    border-radius: 90px;
`

const NextButton = styled.a.attrs({href: "#"})`
    color: black;
    text-decoration: none;
    font-size: 30px;
    background-color: #00d2ff;
    padding: 10px 100px;
    border-radius: 90px;
    width: 80px;
    margin: 0 auto;
  
    @media screen and (max-width: 620px) {
        font-size: 20px;
        width: 50px;
        font-weight: bolder;
    }
`

const NextLayer = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px;
`

interface InviteMemberProps {
    chatObj :any,
    showInviteMember :boolean,
    setShowInviteMember :(active :boolean) => void
}

interface ProfileProps {
    profile :any,
    newMessageMember :string[],
    setNewMessageMember :(active :string[]) => void
}

interface MemberProfileProps {
    userId :string
}

function Profile({profile, newMessageMember, setNewMessageMember} :ProfileProps) {
    const [isOnline, setIsOnline] = useState<boolean>(false)
    const [profileChecked, setProfileChecked] = useState<boolean>(false)

    useEffect(() => {
        const getOnlineStatus = onValue(ref(getDatabase(), `status/${profile.userId}/state`), (snapshot) => {
            if (snapshot.exists() && snapshot.val() === "online") {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })

        return (() => {
            getOnlineStatus()
        })
    }, [profile])

    useEffect(() => {
        if (newMessageMember.indexOf(profile.userId) > -1) {
            setProfileChecked(true)
        } else {
            setProfileChecked(false)
        }
    }, [profile, newMessageMember])

    function onProfileChange(event :any) {
        if (event.target.checked) {
            setNewMessageMember([...newMessageMember, profile.userId])
        } else {
            setNewMessageMember(newMessageMember.filter(e => e !== profile.userId))
        }
    }

    if (profile !== undefined) {
        return (
            <FlexBox>
                <ProfileLayer>
                    <ProfileImage image={profile.profilePhoto}/>
                    {isOnline ? (
                        <OnlineLight/>
                    ) : null}
                    <div>
                        <h3>{profile.name}</h3>
                        <p>{"@" + profile.id}</p>
                    </div>
                </ProfileLayer>
                <SettingMenu>
                    <input type="checkbox" id={profile.userId} onChange={onProfileChange} checked={profileChecked} hidden/>
                    <SelectButton htmlFor={profile.userId}/>
                </SettingMenu>
            </FlexBox>
        )
    } else {
        return null
    }
}

function MemberProfile({userId} :MemberProfileProps) {
    const [profile, setProfile] = useState<any>()
    useEffect(() => {
        getDoc(doc(getFirestore(), "users", userId)).then((doc) => {
            if (doc.exists()) {
                setProfile(doc.data())
            }
        })
    })

    if (profile !== undefined) {
        return (
            <ProfilePreviewLayer>
                <PreviewProfileImage image={profile.profilePhoto}/>
                <p>{profile.name}</p>
            </ProfilePreviewLayer>
        )
    } else {
        return null
    }
}

function InviteMember({chatObj, showInviteMember, setShowInviteMember} :InviteMemberProps) {
    const [following, setFollowing] = useState<object[]>([])
    const [searchValue, setSearchValue] = useState<string>("")
    const [userSearchResult, setUserSearchResult] = useState<object[]>([])
    const [newMessageMember, setNewMessageMember] = useState<string[]>([])

    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj] = [data.userObj]

    useEffect(() => {
        const getFollowing = onSnapshot(collection(getFirestore(), `users/${userObj.userId}/followings`), (querySnapshot) => {
            let followingArray :any[] = []
            querySnapshot.forEach((followDoc) => {
                if (followDoc.exists()) {
                    getDoc(doc(getFirestore(), "users", followDoc.data().userId)).then((doc) => {
                        if (doc.exists()) {
                            followingArray.push(doc.data())
                        }
                    })
                }
            })
            setFollowing(followingArray)
        })

        return(() => getFollowing())
    }, [userObj, chatObj])

    useEffect(() => {
        if (searchValue.indexOf("@") === 0) {
            if (following.length !== 0) {
                setUserSearchResult(following.filter((e :any) => e.id.search(searchValue.slice(1)) !== -1 && chatObj.member.indexOf(e.userId) < 0))
            }
        } else {
            if (following.length !== 0) {
                setUserSearchResult(following.filter((e :any) => e.name.search(searchValue) !== -1 && chatObj.member.indexOf(e.userId) < 0))
            }
        }
    }, [searchValue, showInviteMember])

    function onNextClick() {
        newMessageMember.map(async (e) => {
            await updateDoc(doc(getFirestore(), "chats", chatObj.id), {
                member: arrayUnion(e)
            })
        })

        setSearchValue("")
        setUserSearchResult([])
        setNewMessageMember([])
        setShowInviteMember(false)
    }

    if (showInviteMember) {
        return (
            <ModalOverBox>
                <ModalOverlay onClick={() => setShowInviteMember(false)}/>
                <ModalLayer>
                    <ModalTitle>Invite user</ModalTitle>
                    <SearchLayer>
                        <UserSearchInput type={"text"} placeholder={"Search user"} onChange={(e :any) => setSearchValue(e.target.value)}/>
                    </SearchLayer>
                    <MemberPreviewLayer>
                        {newMessageMember.map((e, i) => (
                            <MemberProfile userId={e} key={i}/>
                        ))}
                    </MemberPreviewLayer>
                    {userSearchResult.map((e :any, i) => (
                        <Profile
                            profile={e}
                            newMessageMember={newMessageMember}
                            setNewMessageMember={setNewMessageMember}
                            key={i}/>
                    ))}
                    <NextLayer>
                        <NextButton onClick={onNextClick}>Invite</NextButton>
                    </NextLayer>
                </ModalLayer>
            </ModalOverBox>
        )
    } else {
        return null
    }
}

export default InviteMember