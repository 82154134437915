import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import useWindowDimensions from "./getDisplaySize"
import MobileNavbar from "./mobile/mobileNavbar";

const SearchIcon = require("../../images/icons/search.png")
const DarkSearchIcon = require("../../images/icons/dark/search.png")
const Logo = require("../../images/tunip-logo.png")
const WhiteLogo = require("../../images/chat-logo-white.png")

const NavbarBox = styled.div `
    width: 100%;
    height: 110px;
    top: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.themeColor};
    z-index: 1000;
`

const LogoImage = styled.img `
    width: 50px;
    height: 50px;
    margin-top: 13px;
`

const LogoBox = styled.a `
    display: flex;
    margin-top: -5px;
    margin-right: 120px;
    margin-left: 0;
    width: 260px;
    color: ${props => props.theme.color};
    text-decoration: none;
  
    @media screen and (max-width: 1725px) {
        width: 40px;
        margin-right: 75px;
    }

    @media screen and (max-height: 800px) {
        width: 40px;
        margin-right: 77px;
    }
  
    h1 {
        margin: 1rem;
        font-weight: bolder;
        color: ${props => props.theme.color};
        
        @media screen and (max-width: 1725px) {
            display: none;
        }
      
        @media screen and (max-height: 800px) {
            display: none;
        }
    }
`

const SearchBox = styled.div `
    background-color: ${props => props.theme.backgroundColor};
    width: 750px;
    height: 50px;
    border-radius: 90px;
    box-shadow: ${props => props.theme.boxShadow};
    margin: 50px 0;

    @media screen and (max-width: 1110px) {
        width: 600px;
    }
  
    @media screen and (max-height: 750px) {
        width: 600px;
    }
    
    img {
        margin-left: 5px;
    }
    
    input {
        position: absolute;
        height: 40px;
        width: 670px;
        border: 0;
        background-color: #00000000;
        font-size: 35px;
        padding-top: 5px;
        outline: none;
        color: ${props => props.theme.color};
      
        @media screen and (max-width: 1110px) {
            width: 500px;
        }

        @media screen and (max-height: 750px) {
            width: 500px;
        }
    }
`

const Margin = styled.div`
    width: 377px;
    @media screen and (max-width: 1720px) {
        display: none;
    }
    
    @media screen and (max-height: 750px) {
        display: none;
    }
`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

function Navbar() {
    const [logo, setLogo] = useState(Logo)

    const {width, height} = useWindowDimensions()
    const data = useSelector((state :any) => state)
    // @ts-ignore
    const [userObj, isDarkMode, text] = [data.userObj, data.isDarkMode, data.text]
    const navigate = useNavigate()

    useEffect(() => {
        if (isDarkMode) {
            setLogo(WhiteLogo)
        } else {
            setLogo(Logo)
        }
    }, [isDarkMode])

    if (width > 1118 && height > 850) {
        return (
            <NavbarBox>
                <FlexBox>
                    <LogoBox onClick={() => navigate("/", {state: (userObj)})}>
                        <LogoImage src={logo} alt={"tunip logo"}/>
                        <h1>tunip chat</h1>
                    </LogoBox>
                    <SearchBox>
                        {/* 검색 기능 나중에 */}
                        <img src={isDarkMode ? DarkSearchIcon : SearchIcon} alt="search icon" width={"50px"}/>
                        <input type="text" placeholder={text.search}/>
                    </SearchBox>
                    <Margin></Margin>
                </FlexBox>
            </NavbarBox>
        )
    } else {
        return (
            <MobileNavbar/>
        )
    }
}

export default Navbar